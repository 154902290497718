<template>
  <div>
<!--    :height="tableHeight?tableHeight:643"-->
    <el-table
      ref="table"
      element-loading-text="Loading"
      :data="tableData"
      tooltip-effect="dark"
      :id="tableId"
      highlight-current-row
      @selection-change="handleSelectionChange"
      @row-click="rowClick"
      style="width:100%">
      <el-table-column
        v-if="showSelection"
        type="selection"
        width="55"
        align="center"
      ></el-table-column>
      <el-table-column
        v-for="(item,index) in tableLabel"
        :width="item.width ? item.width : ''"
        :key="index"
        :align="item.align ? item.align : 'center'"
        :label="item.label"
        :prop="item.param"
        show-overflow-tooltip
        :render-header="renderHeader2"
      >
        <!--        :sortable='true'-->

        <template slot-scope="scope">
          <span v-if="item.render" style="color:#606266">
            {{render(scope.row)}}
          </span>
          <el-tooltip v-else-if="item.status&&scope.row[item.param]=='在线'"
                      class="item" effect="dark"
                      :content="'在线时间：'+(scope.row.onlineTime?scope.row.onlineTime:'--')"
                      placement="top-end">
            <span  style="color:#2C36C3">
              在线
            </span>
          </el-tooltip>
          <el-tooltip v-else-if="item.status&&scope.row[item.param]=='离线'"
                      class="item" effect="dark"
                      :content="'最后通讯时间：'+ (scope.row.onlineTime?scope.row.onlineTime:'--')"
                      placement="top-end">
            <span  style="color:red">
              离线
            </span>
          </el-tooltip>

          <el-tooltip v-else-if="item.isOnline&&scope.row[item.param]=='离线'"
                      class="item" effect="dark"
                      :content="'最后通讯时间：'+ (scope.row.finalPostDate?scope.row.finalPostDate:'--')"
                      placement="top-end">
            <span  style="color:red">
              离线
            </span>
          </el-tooltip>
          <el-tooltip v-else-if="item.isOnline&&scope.row[item.param]=='在线'"
                      class="item" effect="dark"
                      :content="'在线时间：'+(scope.row.finalPostDate?scope.row.finalPostDate:'--')"
                      placement="top-end">
            <span  style="color:#2C36C3">
              在线
            </span>
          </el-tooltip>
          <span v-else style="color:#606266">{{scope.row[item.param]}}</span>
        </template>

      </el-table-column>
      <el-table-column
        v-if="tableOption.label"
        :width="tableOption.width ? tableOption.width : ''"
        :label="tableOption.label"
        align="center"
        class-name="fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            plain
            v-for="(item,index) in tableOption.options"
            :key="index"
            :type="item.type ? item.type : 'primary'"
            :icon="item.icon ? item.icon : ''"
            @click="handleButton(item.methods,scope.row)"
            size="mini"
          >
            <span v-if='item.videoName'>{{scope.row.videoName}}</span>
            <span v-if='!item.videoName'>{{item.label}}</span>
          </el-button>

        </template>
      </el-table-column>
      <el-table-column
        v-if="isStatistics.label"
        :width="isStatistics.width ? isStatistics.width : ''"
        :label="isStatistics.label"
        align="center"
        class-name="fixed-width"
      >
        <template slot-scope="scope">
          <el-checkbox label="" :checked="scope.row.notCount?true:false" @change='isStatisticsChange($event,scope.row)'></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column
        v-if="toTop.label"
        :width="toTop.width ? toTop.width : ''"
        :label="toTop.label"
        align="center"
        class-name="fixed-width"
      >
        <template slot-scope="scope">
          <el-checkbox label="" :checked="scope.row.sort!=null?true:false" @change='change($event,scope.row)'></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableVideo.label"
        :width="tableVideo.width ? tableVideo.width : ''"
        :label="tableVideo.label"
        align="center"
        class-name="fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            plain
            v-for="(item,index) in tableVideo.options"
            :key="index"
            :type="item.type ? item.type : 'primary'"
            :icon="item.icon ? item.icon : ''"
            @click="handleButton(item.methods,scope.row)"
            size="mini"
          >
            {{item.label}}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: right;margin-top: 8px"
      :page-sizes="[ 10, 20, 50 , 200]"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="pager.currentPage"
      :page-size.sync="pager.pageSize"
      layout="total, prev, pager, next, sizes"
      :total="pager.total"
    ></el-pagination>

  </div>
</template>

<script>
export default {
  data(){
    return {
    }
  },
  props:{
    tableId: String,
    tableHeight: Number,
    tableData:{  // 表格数据
      type:Array,
      default: () => {
        return []
      }
    },
    tableLabel:{   // label信息
      type:Array,
      default: () => {
        return []
      }
    },
    tableOption:{  // 操作数据
      type:Object,
      default: () => {
        return {}
      }
    },
    tableVideo:{  // 实时播放
      type:Object,
      default: () => {
        return {}
      }
    },
    toTop:{  // 置顶
      type:Object,
      default: () => {
        return {}
      }
    },
    isStatistics:{  // 是否统计
      type:Object,
      default: () => {
        return {}
      }
    },
    showSelection:{  // 是否显示复选框
      type: Boolean,
      default: false
    },
    pager:{  // 分页信息
      type: Object,
      default: function(){
        return {
          currentPage:1,
          pageSize:10,
          total:0
        }
      }
    }
  },
  methods: {
    renderHeader2(h, { column }) {
      // console.log(column)
      return h("span", {}, [
      column.label =='确认头数' && h( /*这里边可以根据自己的业务来控制自定图标显示与否 不需要的话直接去掉 this.userType == '3' && 就好了*/
          "el-popover",
          {
            props: {
              effect: "dark",
              placement: "top-start",/*可以配置提示出现的位置*/
              trigger: "hover",/*可以配置触发方式*/
              content: "人为确认头数"
            }
          },
          [
            h(
              "i",
              {
                slot: "reference",
                class: "el-icon-info"/*elementui的图标*/
              },
              ""
            )
          ]
        ),
        h("span", {}, column.label)
      ]);
    },
    render(item){
      console.log(item)
      // return item
    },
    getStatus(item){
      console.log(item)
      return item.id
    },
    // 触发自定义按钮操作
    handleButton(method, row){
      this.$emit('handleButton',method, row);
    },
    // 获取所有的复选框选项
    getAllSelections(){
      return this.$refs.table.selection;
    },
    // size发生变化时，令currentPgae为1，并发送自定义事件
    handleSizeChange(val) {
      this.pager.currentPage = 1;
      this.$emit('handleSizeChange', val);
    },
    // currentPage发生变化时，发送自定义事件
    handleCurrentChange(val) {
      this.$emit('pagination', val);
    },
    change(e,val){
      let deviceId = ''
      if (val.type){
        deviceId = val.id
      }else {
        deviceId = val.deviceId
      }
      if (e){
        this.$emit('changeTop', deviceId);
      }else {
        this.$emit('cancelTop',deviceId);
      }
    },
    isStatisticsChange(e,val){
      let deviceId = ''
      if (val.type){
        deviceId = val.id
      }else {
        deviceId = val.deviceId
      }
      if (e){
        this.$emit('changeStatistics', e, deviceId);
      }else {
        this.$emit('cancelStatistics', e, deviceId);
      }
    },
    //选中
    handleSelectionChange(val){
      console.log(val);
      this.$emit('handleSelectionChange',val);
    },
    //选中行
    rowClick(val){
      this.$emit('rowClick',val);
    }
  }
}
</script>
<style scoped>
>>> .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #2C36C3;
  border-color: #2C36C3;
}

>>> .el-table th > .cell {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  /*color: #a2a4aa;*/
  font-weight: 500;
}
</style>
