<template>
<div id="app2">
    <router-view v-if='isRouterAlive' />
</div>
</template>

<script>

export default {
  name: 'App',
  data(){
    return{
      isRouterAlive:true
    }
  },
  provide(){
    return{
      reload:this.reload
    }
  },
  created() {
    // 在页面加载时读取sessionStorage
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
    }
    // 在页面刷新时将store保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  },
  methods:{
    reload(){
      this.isRouterAlive = false
      this.$nextTick(function(){
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style >
@font-face {
  font-family: 'iconfont';  /* Project id 2647755 */
  src: url('//at.alicdn.com/t/font_2647755_1rzxw1hh13d.woff2?t=1631235879482') format('woff2'),
  url('//at.alicdn.com/t/font_2647755_1rzxw1hh13d.woff?t=1631235879482') format('woff'),
  url('//at.alicdn.com/t/font_2647755_1rzxw1hh13d.ttf?t=1631235879482') format('truetype');
}
.iconfont {
    font-family: 'iconfont';
    display: inline-block;
    text-align: center;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
#app2{
  height: calc(100vh);
}
</style>
