import axios from 'axios'
// Axios请求常量
import router from '../router';
import { Message } from 'element-ui';
import store from '../store'
import loginApi from './user/login'
import qs from 'qs'


const IS_PROD = process.env.NODE_ENV ==='development';
// 请求的目标URL
axios.defaults.baseURL = store.state.baseUrl;
// 设置请求超时时间
axios.defaults.timeout = 8000;
// 允许携带Cookie
axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;


// axios.interceptors.request.use((config) => {
//     let token = sessionStorage.getItem("token");
//     if( token != null ) {
//         config.headers.token = token;
//     }
//     return config;
// }, (error) => { Promise.reject(error) });
axios.interceptors.request.use(function (config) {
    if(config.data){
        if(config.data.contentType){
            if(config.data.contentType=='application/x-www-form-urlencoded;charset=UTF-8'){
                config.headers['Content-Type']='application/x-www-form-urlencoded;charset=UTF-8';
                config.data = qs.stringify(config.data);
            }
        }
    }
    // config.headers['Content-Type ']='application/x-www-form-urlencoded;charset=UTF-8';
    config.withCredentials = true
    config.crossDomain = true;
  // 通过拦截request请求,主动为 请求头,追加新属性 Authorization,等于 token 值
    if (localStorage.getItem('token')) {
        config.headers['x-auth-token'] = localStorage.getItem('token');
    }
    return config;
    }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
})

// 添加响应拦截器

axios.interceptors.response.use(
    response=>{
    // 对响应数据做点什么
        // if(response=== undefined){

        //     router.push("/login")
        // }
        return response;
    }, 
    error=> {
    // 对响应错误做点什么
    return Promise.reject(error);

});

export default {
    // GET请求
    get: function (url, param = {}) {
        if(store.state.farmId){
            param.farmId = store.state.farmId;
            param.regionId = store.state.farmId;
        }
        return new Promise((resolve, reject) => {
            axios.defaults.withCredentials=true;
            axios.defaults.crossDomain=true;
            axios.get(url, { params:  param  }).then(res=>{
                this.requestSuccess(res, resolve, reject)

            }).catch(err => this.requestFail(err,  reject));
        });
    },
    // POST请求
    post: function (url, param =  {}) {
        if(store.state.farmId){
            param.farmId = store.state.farmId;
            param.regionId = store.state.farmId;
        }
        return new Promise((resolve, reject) => {
          axios.defaults.withCredentials=true;
          axios.defaults.crossDomain=true;
            axios.post(url,   param  ).then(res => {
                this.requestSuccess(res, resolve, reject)

            }).catch(err => this.requestFail(err,  reject));
        });
    },
    // DELETE请求
    delete: function (url, param =  {}) {
        if(store.state.farmId){
            param.farmId = store.state.farmId;
            param.regionId = store.state.farmId;
        }
        return new Promise((resolve, reject) => {
            axios.delete(url,  { params:  param  }).then( res => {
                this.requestSuccess(res, resolve, reject)

            }).catch(err => this.requestFail(err,  reject));
        });
    },
    // PUT请求
    patch: function (url, param =  {}) {
        if(store.state.farmId){
            param.farmId = store.state.farmId;
            param.regionId = store.state.farmId;
        }
        return new Promise((resolve, reject) => {
            axios.patch(url,  param ).then( res => {
                this.requestSuccess(res, resolve, reject)

            }).catch(err => this.requestFail(err,  reject));
        });
    },
    // PUT请求
    put: function (url, param =  {}) {
        if(store.state.farmId){
            param.farmId = store.state.farmId;
            param.regionId = store.state.farmId;
        }
        return new Promise((resolve, reject) => {
            axios.put(url,  param ).then( res => {
                this.requestSuccess(res, resolve, reject)

            }).catch(err => this.requestFail(err,  reject));
        });
    },
    // 上传文件
    upload: function (url, file) {
        let form = new FormData();
        form.append("file", file);
        return new Promise((resolve, reject) => {
            axios.post(url, form).then(({ data: res }) => {
                this.requestSuccess(res, resolve, reject)

            }).catch(err => this.requestFail(err,  reject));
        });
    },

    // 请求成功处理方法
    requestSuccess(res, resolve, reject) {
        switch (res.code?res.code:res.statusCode) {
            // 请求处理成功
            case 200: {
                if(res.data != null){
                    //res.data = res.data["   "];
                    // 返回值中包含Token就更新Token信息
                    if(res.data.accessToken != null) {
                        sessionStorage.setItem("token", res.data.accessToken)
                    }
                }
                // 走Promise
                resolve(res);
                break;
            }

            case 401: {
                //Message.toast(res.msg);
                router.push("/")
                break;
            }

            case 300: {
                sessionStorage.removeItem("token");
                this.toLogin(res.message);
                break;
            }

            case -1001: {
                Message.warning(res.message)
                break;
            }

            case -1000: {
                localStorage.removeItem("token");
                localStorage.removeItem('menu')
                this.toLogin(res.message);
                break;
            }
            case -1012: {
                Message.warning(res.message)
                break;
            }
            // 兜底方案
            default: {
                //Message.toast(res.msg);
                reject(res);
            }
        }
    },
    // 请求失败通用模板

    requestFail(err,resolve, reject) {
        // console.log(444)
        // reject(err);
      this.toLogin();
        // Message.error({message:"服务器请求失败，请联系站长进行处理！"});
    },

    // 跳转到登录页并显示消息
  toLogin(msg) {
      this.clearTabs()
    
      axios(
        'post',
        // 'http://yozhu.cn/client/sys/getsysInfo',
        // 'http://unify.hsfxm.cn/client/sys/getsysInfo',
        store.state.baseUrl+'/upms/UnifySmSysInfo/sys/getsysInfo',
        {sysCode:'IoTLogin'},
      ).then(res=>{
        //   console.log(res)
        let url = res.result.sysLoginPath
        window.location.href = url
      })

  },

  clearTabs() {
    let arr = []
    loginApi.getMenusBySysCode('iot').then(res => {
      if (res.code === 200) {
        if (res.result) {
          res.result.map(item => {
            if (item.children) {
              item.children.map(x => {
                arr.push(x)
              })
            } else {
              arr.push(item)
            }
          })
        }
        if (arr) {
          arr.map(item => {
            this.$store.commit('closeTab', item)
          })
        }
      }
    })
    // axios('get', '/Ifspower/getCurrUserMenu').then(res => {
    //   if (res.statusCode === 200) {
    //     if (res.data) {
    //       res.data.map(item => {
    //         if (item.children) {
    //           item.children.map(x => {
    //             arr.push(x)
    //           })
    //         } else {
    //           arr.push(item)
    //         }
    //       })
    //     }
    //     if (arr) {
    //       arr.map(item => {
    //         this.$store.commit('closeTab', item)
    //       })
    //     }
    //   }
    // })
  },

}
