export default {
  state: {
    currentMenu: {}, // 左侧选择当前的路由
    isNewTab:false,//是否是顶部新增的节点
    tabList: [
      {
        path: '/home',
        target: 'home',
        name: '首页',
        // icon: 's-platform'
      }
    ] // tabs列表
  },
  mutations: {
    // 根据左侧导航栏设置tabList的值
    selectMenu(state, val) {
      if (val.target !== 'home') {
        state.currentMenu = val
        const result = state.tabList.findIndex(item => item.target === val.target)
        if (result === -1) {
          // val.label = val.label?val.label:val.name;
          // val.name = val.target;
          state.tabList.push(val)
          state.isNewTab = true;
        }else{
          state.isNewTab = false
        }
      } else {
        state.currentMenu = null
      }
    },
    // 关闭tab方法
    closeTab(state, val) {
      const result = state.tabList.findIndex(item => item.target === val.target)
      if (result!=-1){
        state.tabList.splice(result, 1)
      }
    },
    // 刷新页面之后存进来的值
    changeSetTabs(state, val) {
      state.tabList = val
    }

  },
  actions: {}
}
