<template>
    <el-dialog
      title='提示'
      :visible.sync='dialogVisible'
      width='30%'
      :before-close='handleClose'>
      <span slot='footer' class='dialog-footer'>
        <el-button @click='dialogVisible = false'>取 消</el-button>
        <el-button type='primary' @click='dialogVisible = false'>确 定</el-button>
      </span>
    </el-dialog>
</template>

<script>
export default {
  name: 'videoModel',
  props:{
    url:String,
    visible:Boolean
  },
  data() {
    return {
      dialogVisible: false``
    };
  },
  watch: {
    visible(val) {
      this.dialogVisible = val
    }
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    }
  }
}
</script>

<style scoped>

</style>
