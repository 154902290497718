import Vue from 'vue'
import Vuex from 'vuex'
import user from './user/index'
import menu from './menu/index'
import tab from './menu/tab'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    // 用户名
    userName: localStorage.getItem('userName'),
    menuList:localStorage.getItem('menu'),
    farmId: "",
    // baseUrl:'http://192.168.200.13:8081/api',
    // baseUrl:'http://iot.rnpig.com/api',
    // baseUrl:'http://39.98.92.219:8080/api',
    baseUrl:'http://api.hsfxm.cn/api',
    // baseUrl:'http://api.hsfxm.cn/api',
    // baseUrl:'http://api.hfmygnqszcyh.com/api'
  },
  mutations: {
    upDataUserName(state, userName) {
      state.userName = userName
    },
    getMenu(state,data){

      state.menuList = data;
    },
    setFarmId(state,data){
      state.farmId = data;
    },
  },
  actions: {
    login({commit}, res){
      commit('upDataUserName', res)
    }
  },
  modules: {
    user,
    menu,
    tab
  }
})
