<template lang="">
  <div>
    <searchForm :formOptions="formOptions" @onSearch="onSearch"/>
    <page-table
      :tableData="tableData"
      :tableLabel="tableLabel"
      :tableOption="tableOption"
      :pager="pager"
      @pagination="loadTable"
      @handleButton="tableBtnHandle(arguments)"
    ></page-table>
  </div>
</template>
<script>
import pageTable from '@/components/pageTable'
import searchForm from "@/components/searchForm";
export default {
  name: 'template',
    components:{
        pageTable,
        searchForm
    },
    data(){

        return{
          formOptions: [
            {
              label: '意见内容',
              prop: 'content',
              element: 'el-input'
            },
            {
              label: '类型',
              prop: 'type',
              element: 'el-select',
              options: [
                { label: '给点意见', value: '1' },
                { label: '售后问题', value: '2' }
              ]
            },
            {
              label: '状态',
              prop: 'status',
              element: 'el-select',
              options: this.getFeedbackStatus()
            },
            {
              label: '提交时间',
              prop: 'date',
              type: 'daterange',//monthrange | datetimerange | daterange |date
              element: 'el-date-picker'
            }
          ],
          tableData: [{
              name: '王小虎',
              type: '上海市普陀区金沙江路 1518 弄'
              }, {
              name: '王小虎',
              type: '上海市普陀区金沙江路 1518 弄'
              }, {
              name: '王小虎',
              type: '上海市普陀区金沙江路 1518 弄'
              }, {
              name: '王小虎',
              type: '上海市普陀区金沙江路 1518 弄'
              }],
          tableLabel:[
              {label:'c1', param:'type'},
              {label:'c2', param:'name'}
          ],
          tableOption: {
              label: '操作',
              options: [
                  { label: '编辑', methods: 'editDetail' },
                  { label: '删除', methods: 'detailDetail',type:'danger' }
              ]
          },
          pager:{
              pageSize: 10,  // 每页数据量
              currentPage: 1, // 分页  当前页
              total:0,  //列表总数
          }
        }
	},
    methods:{
        loadTable(val){

        },
        tableBtnHandle(arg){
            console.log(arg[0])
            console.log(arg[1])
        },
        onSearch (val) {
          console.log(111)
          console.log(val)
        },
        getFeedbackStatus(){
          return [{
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
        }, {
          value: '选项3',
          label: '蚵仔煎'
        }, {
          value: '选项4',
          label: '龙须面'
        }, {
          value: '选项5',
          label: '北京烤鸭'
        }]
        }

    }
}
</script>
