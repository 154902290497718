import axios from '../http_axios'

export default {
  addCollection: (obj) => {
    return axios({
      method: 'post',
      url: '/upms/MenuCollection/add',
      data:{
        ...obj
      }
    })
  },
  removeCollection: (obj) => {
    return axios({
      method: 'post',
      url: '/upms/MenuCollection/remove',
      data:{
        ...obj
      }
    })
  },

  listCollection: (obj) => {
    return axios({
      method: 'get',
      url: '/upms/MenuCollection/list',
      params:{
        ...obj
      }
    })
  },

  addCommunication: (obj) => {
    return axios({
      method: 'post',
      url: '/system/Communication/publish',
      data:{
        ...obj
      }
    })
  },
}
