<template>
  <div>
    <div class='page flex-col'>
      <div class="loginBox">
        <div :class='getClass()'>
          <div class='bd1 flex-col'>
            <div class="loginTitle">
              <img style="height: 60px;" src="../assets/1x.png" alt="">
              <div style="margin-left: 20px;">
                <div style="font-size: 30px; text-align: left;">物联环控系统</div>
                <div style="font-size: 15px;">Winland IOT System</div>
              </div>
            </div>
            <div style="display: flex; align-items: center; margin-left: 25px; margin-top: 20px;">
              <div>
                <span style="color: rgb(47, 126, 207); font-size: 48px;">{{ day }}</span>
                <span style="color: rgb(47, 126, 207); font-size: 24px; margin-left: 12px; margin-right: 12px;">{{ month }}</span>
              </div>
              <div style="width: 1px; height: 40px; background: rgb(235, 238, 245) none repeat scroll 0% 0%; border: 1px solid rgb(235, 238, 245); margin-right: 25px;">

              </div><div class="loginTip"><div style="color: rgba(42, 42, 55, 0.6); font-size: 15px;"> {{ hello[i] }}</div>
              <div style="color: rgba(42, 42, 55, 0.6); font-size: 14px;"> {{ enHello[i] }}</div></div>
            </div>
            <div style="margin: 0px auto; width: 90%;">
              <el-form :model='loginForm' :rules='rules' ref='loginFormRef'>
                <span class='info2'>账号</span>
                <div class='layer2 flex-col'>
                  <el-form-item prop='username'>
                    <el-input
                      placeholder='请输入账号'
                      prefix-icon='el-icon-user'
                      v-model='loginForm.username'
                      @keyup.enter.native="useVerify"
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <span class='info2' style="margin-top: 10px">密码</span>
                <div class='layer3 flex-col'>
                  <div class='main1 flex-row'>
                    <el-form-item prop='password' style="width: 100%">
                      <el-input prefix-icon='el-icon-unlock'
                                placeholder='请输入密码'
                                v-model='loginForm.password'
                                @keyup.enter.native="useVerify"
                                show-password>
                      </el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class='layer4 flex-row' v-if='loginError'>
                  <i class='el-icon-warning-outline icon2'></i>
                  <span class='word4'>密码错误，请重新输入</span>
                </div>
                <el-form-item style="width: 100%">
                  <el-button  style="width: 100%;background-color: #1890ff" type='primary' class='flex' @click='useVerify'>
                    <span class='info4'>登&nbsp;录</span>
                  </el-button>
                </el-form-item>
              </el-form>

            </div>
            <div class='mod2 flex-col'>
              <div class='box1 flex-col'>
                <div
                  style='height: 60px;background:#EFEFEF;border-radius:0 0 16px 16px;display: flex;align-items: center;justify-content:center'>
                  <el-tooltip class="item" effect="light" placement="top">
                    <template slot='content'>
                      <img src='../assets/img/code.jpg' width='100' height='100'>
                      <img src='../assets/img/an.png' width='60' height='60' style='margin-bottom: 15px'>
                      <img src='../assets/img/code2.png' width='100' height='100'>
                      <img src='../assets/img/phone.png' width='40' height='50' style='margin:0 10px 25px'>
                    </template>
                    <div style='display: flex;justify-content:center'>
                      <img src='../assets/img/app.png' height='30' width='30'>
                      <span style='margin-left: 10px'>下载物联环控APP</span>
                    </div>
                  </el-tooltip>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!--    <Verify @success="success" v-if="upload.uploadData"  :mode="'pop'" :captchaType="'blockPuzzle'" :imgSize="{ width: '330px', height: '155px' }" ref="verify"></Verify>-->
  </div>
</template>
<script>
import Verify from '@/components/verifition/Verify'
import loginApi from '../api/user/login'
import Template from './setting/template'

export default {
  data() {
    return {
      i: 1,
      constants: {},
      checked: false,
      month: '07月',
      day: '24',
      hello: [
        '一次只做一件事，做到最好。',
        '苍天不负有心人。',
        '实践长才干。',
        '相信你自己。'
      ],
      enHello: [
        'Do one thing at a time, do the best.',
        'All things come to those who wait.',
        'In doing we learn.',
        'Believe in yourself.'
      ],
      bg: [
        require('../assets/img/login_bg.png'),
        require('../assets/img/login_bg.png')
      ],
      logo: [
        require('../assets/img/logo2.png'),
        require('../assets/img/logo2.png')
      ],
      loginError: false,

      loginForm: {
        username: undefined,
        password: undefined
      },
      rules: {
        username: [
          { required: true, message: '请输入账号', trigger: 'change' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'change' },
        ]
      },
      upload: {
        uploadData: false,
        index: 0
      }
    }
  },
  components: {
    Template,
    Verify
  },
  computed: {
    token() {
      console.log(this.$route)
      return this.$route.query.token
    }
  },
  watch:{
    token: {
      deep: true,
      immediate:true,
      handler (token) {
        console.log(token)
      }
    }
  },
  //周期函数
  created() {
    this.i = Math.floor(Math.random() * 4)
    this.j = Math.floor(Math.random() * 2)
    this.getData()
  },
  methods: {
    getClass() {
      if (this.j == 0) return 'group2 bgColor1 flex-col'
      if (this.j == 1) return 'group2 bgColor2 flex-col'
    },
    useVerify() {
      let that = this
      this.$store.commit('setFarmId', '')
      that.$refs.loginFormRef.validate(valid => {
        if (!valid){
          return
        }
        loginApi.userLogin({
            username: that.loginForm.username,
            password: that.loginForm.password
          }
        ).then(res => {
          if (res.code == 200) {
            localStorage.setItem('token', res.result.accessToken)
            localStorage.setItem('userId', res.result.userVO.userId)
            localStorage.setItem('linkPohone', res.result.userVO.linkPohone)
            // that.clearTabs()
            that.$router.push('/home')
          } else {
            this.$message.error(res.message)
          }
        }).catch(err => {
          this.$message.error('系统异常，请联系管理员！')
        })
      })
    },
    clearTabs() {
      let arr = []
      loginApi.getMenusBySysCode('iot').then(res => {
        if (res.code === 200) {
          if (res.result) {
            res.result.map(item => {
              if (item.children) {
                item.children.map(x => {
                  arr.push(x)
                })
              } else {
                arr.push(item)
              }
            })
          }
          if (arr) {
            arr.map(item => {
              this.$store.commit('closeTab', item)
            })
          }
        }
      })
    },
    getData() {
      let now = new Date()
      if (now.getMonth() + 1 < 10) {
        if (now.getMonth() == 0) this.month = '一月'
        if (now.getMonth() == 1) this.month = '二月'
        if (now.getMonth() == 2) this.month = '三月'
        if (now.getMonth() == 3) this.month = '四月'
        if (now.getMonth() == 4) this.month = '五月'
        if (now.getMonth() == 5) this.month = '六月'
        if (now.getMonth() == 6) this.month = '七月'
        if (now.getMonth() == 7) this.month = '八月'
        if (now.getMonth() == 8) this.month = '九月'
        if (now.getMonth() == 9) this.month = '十月'
        if (now.getMonth() == 10) this.month = '十一月'
        if (now.getMonth() == 11) this.month = '十二月'
        if (now.getDate() < 10) {
          this.day = '0' + now.getDate()
        } else {
          this.day = now.getDate()
        }
      }
    },
    findPassword() {
      alert('忘记密码')
    }
  }
}
</script>
<style scoped>
/*/deep/ .el-input {*/
/*  position: relative;*/
/*  font-size: 16px;*/
/*  display: inline-block;*/
/*  width: 520px;*/
/*  height: 56px;*/
/*  line-height: 56px;*/
/*}*/

/*/deep/ .el-input__inner {*/
/*  -webkit-appearance: none;*/
/*  background-color: rgba(100, 100, 100, 0);*/
/*  background-image: none;*/
/*  border-radius: 8px;*/
/*  border: 1px solid #979797;*/
/*  box-sizing: border-box;*/
/*  color: #606266;*/
/*  display: inline-block;*/
/*  font-size: inherit;*/
/*  height: 50px;*/
/*  line-height: 50px;*/
/*  outline: 0;*/
/*  width: 100%;*/
/*}*/

/*/deep/ .el-form-item__error {*/
/*  color: #F56C6C;*/
/*  font-size: 16px;*/
/*  line-height: 1;*/
/*  padding-top: 4px;*/
/*  position: absolute;*/
/*  top: 100%;*/
/*  left: 0;*/
/*}*/

/*/deep/ .el-button {*/
/*  display: flex;*/
/*  height: 58px;*/
/*  line-height: 58px;*/
/*  border-radius: 8px;*/
/*  background-color: #2F7ECF;*/
/*  align-self: center;*/
/*  margin-top: 55px;*/
/*  margin-bottom: 55px;*/
/*  width: 520px;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  margin-left: 28px;*/
/*}*/
/*>>> .el-form-item {*/
/*  margin-bottom: 0px;*/
/*}*/
/*>>> .el-input--prefix .el-input__inner {*/
/*  padding-left: 40px;*/
/*}*/
/*>>> .el-input__icon {*/
/*  height: 100%;*/
/*  width: 35px;*/
/*  text-align: center;*/
/*  transition: all .3s;*/
/*  line-height: 40px;*/
/*}*/
</style>
<style>
.page{
  width: 100%;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  height: calc(100vh);
  background-image: url("../assets/img/login_bg.png");
}
.loginBox {
  position: absolute;
  top: 15%;
  left: 60%;
  min-width: 460px;
  border-radius: 10px;
  background: #fff;
}
.loginTitle {
  padding-top: 30px;
  margin-left: 25px;
  display: flex;
  color: #358aff;
  text-align: left;
  align-items: center;
}
.info2 {
  z-index: 11;
  width: 36px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.7);
  font-size: 14px;
  line-height: 25px;
  text-align: left;
  align-self: flex-start;
  margin-top: 20px;
  margin-bottom: 10px;
}
</style>
<!--<style src='../assets/css/common.css' />-->
<!--<style scoped src='../assets/css/login.css' />-->
