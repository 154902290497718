<template lang="">
    <page-table
    :tableData="tableData"
    :tableLabel="tableLabel"
    :tableOption="tableOption"
    :pager="pager"
    @pagination="loadTable"
    @handleButton="tableBtnHandle(arguments)"
></page-table>
</template>
<script>
import pageTable from '@/components/pageTable'
export default {
    components:{
        pageTable
    },
    data(){
        return{
            tableData: [{
                name: '王小虎',
                type: '上海市普陀区金沙江路 1518 弄'
                }, {
                name: '王小虎',
                type: '上海市普陀区金沙江路 1518 弄'
                }, {
                name: '王小虎',
                type: '上海市普陀区金沙江路 1518 弄'
                }, {
                name: '王小虎',
                type: '上海市普陀区金沙江路 1518 弄'
                }],
            tableLabel:[
                {label:'c1', param:'type'},
                {label:'c2', param:'name'}
            ],  
            tableOption: {
                label: '操作',
                options: [
                    { label: '编辑', methods: 'editDetail' },
                    { label: '删除', methods: 'detailDetail' }
                ]
            },
            pager:{
                pageSize: 10,  // 每页数据量
                currentPage: 1, // 分页  当前页
                total:0,  //列表总数
            }
        }
	},
    methods:{
        loadTable(val){

        },
        tableBtnHandle(arg){
            console.log(arg[0])
            console.log(arg[1])
        },
        
    }
}
</script>
<style lang="">