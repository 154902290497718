import axios from '../http_axios'

export default {
  userLogin: (obj) => {
    return axios({
      method: 'post',
      // url: '/Ifsuser/login',
      url: '/upms/auth/login',
      headers: {
        // 'Content-Type': 'application/form-data'
        'Content-Type': 'application/x-www-form-urlencoded'//普通表单提交的Content-Type
      },
      transformRequest: [data => {
        let formData = new FormData()
        for(let key in obj){
          formData.append(key, obj[key])
        }
        return formData
      }],
      // data:{
      //   ...obj
      // }
    })
  },

  getRegion: () => {
    return axios({
      method: 'get',
      url: 'upms/PcRegion/getRegion',
    })
  },
  getMenuList: () => {
    return axios({
      method: 'get',
      url: '/upms/RoleMenu/getCurrentUserMenus',
      params:{
        sysCode:'IoTLogin'
      }
    })
  },

  getMenusBySysCode: (name) => {
    return axios({
      method: 'get',
      url: '/upms/RoleMenu/getCurrentUserMenus',
      params:{
        sysCode:name
      }
    })
  },

  getCurrPigFarms: () => {
    return axios({
      method: 'get',
      url: '/upms/PcRegion/getCurrentPigFarm',
    })
  },

  updateUserInfo: (obj) => {
    return axios({
      method: 'post',
      url: '/upms/User/update',
      data:{
        ...obj
      }
    })
  },

  modifyPwd: (obj) => {
    return axios({
      method: 'post',
      url: '/upms/User/updatePassword',
      data:{
        ...obj
      }
    })
  },
}
