<template>
  <header>
    <!--    <div>-->
    <!--      <i class="el-icon-s-fold" v-show="!isCollapse" @click="collapseMenu"></i>-->
    <!--      <i class="el-icon-s-unfold" v-show="isCollapse" @click="collapseMenu"></i>-->
    <!--    </div>-->
    <div class="opt-wrapper other" style="margin-right: 10px">
      <img :src="getIcon()" style="display: inline-block;margin-right: 10px" />

      <div style="display: flex; flex-direction: column-reverse">
        <div>
          <span class="city">{{ weather.province }}{{ weather.city }}{{ weather.district }}</span>
          <span style="margin: 0 5px" class="city">{{ time }}</span>
        </div>

        <div>
          <span class="temperature" v-if="weather.temperature">{{ weather.temperature }}℃</span>
          <span class="weather"> {{ weather.weather }}</span>
        </div>
      </div>
      <!--      <div style='display: flex; flex-direction: column-reverse'>-->
      <!--        <div class='weather'>{{ weather.weather }}</div>-->
      <!--        <div class='temperature' v-if='weather.temperature' >{{ weather.temperature }}℃</div>-->
      <!--      </div>-->
      <!--&lt;!&ndash;      <div v-if='weather.temperature' style='height:16px;line-height: 20px;margin: 0 5px'>℃</div>&ndash;&gt;-->

      <!--      <span class='city'>{{ weather.province }}{{ weather.city }}{{ weather.district }}</span>-->
      <!--      <div style='margin: 0 5px' class='city'>{{ time }}</div>-->
    </div>
    <div class="opt-wrapper">
      <el-form inline>
        <el-form-item style="height:40px; margin-bottom:0;">
          <el-select v-model="defaultProps" filterable placeholder="请选择猪场" @change="changeFarm" style="width: 150px">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <!--          <select-tree :options="options" v-model="selected" />-->
        </el-form-item>
      </el-form>
      <span style="display: inline-block;height: 30px;margin: 0px"></span>

      <el-dropdown @command="changeSys" style="margin-right: 20px">
        <span class="el-dropdown-link"> 切换系统<i class="el-icon-arrow-down el-icon--right"></i> </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="item in sysList" :command="item.sysLink" :key="item.id">{{ item.sysName }} </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <span style="color: #606266;font-size: 16px;display: inline-block;;margin-right: 5px">{{ userName }}</span>
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link borderradius">
          <!--          <img src='../../../assets/logo2.jpg' alt=''>-->
          <img v-if="imageUrl2 != null && imageUrl2 != ''" :src="imageUrl2" alt="" />
          <img v-else src="../../assets/logo.png" alt="" />
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="slef_info"><i class="el-icon-info"></i>基本信息</el-dropdown-item>
          <el-dropdown-item command="edit_pw"><i class="el-icon-edit"></i>修改密码</el-dropdown-item>
          <el-dropdown-item command="logout"><i class="el-icon-switch-button"></i>退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <el-dialog title="修改基本信息" :visible.sync="visibleSlefInfo" :destroy-on-close="true" width="30%">
        <div style="width: 80%">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="">
              <!--               :action="action"-->
              <!--               :headers="headers"-->
              <el-upload
                action="#"
                class="avatar-uploader"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :on-success="handleSucess"
                :before-remove="beforeRemove"
                :with-credentials="true"
                name="files"
                multiple
                :show-file-list="false"
                :on-exceed="handleExceed"
                :http-request="handleUploadFile"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="账号" prop="nickname">
              <el-input v-model="ruleForm.account" disabled />
            </el-form-item>
            <el-form-item label="姓名" prop="name">
              <el-input v-model="ruleForm.nikeName"></el-input>
            </el-form-item>
            <el-form-item label="绑定手机号" prop="phoneNumber">
              <el-input v-model="ruleForm.linkPhone" disabled />
            </el-form-item>
            <el-form-item label="绑定邮箱" prop="email">
              <el-input v-model="ruleForm.userMail" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="visibleSlefInfo = false" size="small">取 消</el-button>
          <el-button type="primary" @click="updateUserInfo" size="small">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="修改密码" :visible.sync="visiblePW" width="30%">
        <div style="width: 80%">
          <el-form :model="pwForm" status-icon :rules="pwRules" ref="pwForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="旧密码" prop="passWord">
              <el-input type="password" v-model="pwForm.passWord" autocomplete="off" show-password></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="pass">
              <el-input type="password" v-model="pwForm.pass" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPass">
              <el-input type="password" v-model="pwForm.checkPass" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="visiblePW = false" size="small">取 消</el-button>
          <el-button type="primary" @click="submitForm('pwForm')" size="small" :loading="loadingBtn">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </header>
</template>
<script>
import { mapState } from 'vuex'
import SelectTree from '@/components/widget/SelectTree.vue'
import { tranBuildType, TreeDataOrg } from '../../libs/tools'
import loginApi from '../../api/user/login'
import store from '../../store'
import moment from 'moment'
import axios from 'axios'
import router from '../../router'
import other from '../../api/other/other'

export default {
  inject: ['reload'],
  components: {
    SelectTree
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.pwForm.checkPass !== '') {
          this.$refs.pwForm.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.pwForm.pass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      },
      action: this.$store.state.baseUrl + '/upms/User/uploadHeadPic',
      imageUrl: '',
      imageUrl2: '',
      userName: '',
      phoneNumber: '',
      pwForm: {
        passWord: '',
        pass: '',
        checkPass: ''
      },
      pwRules: {
        passWord: [{ required: true, message: '请输入账号', trigger: 'change' }],
        pass: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }]
      },
      ruleForm: {},
      rules: {},
      selected: 'A',
      visibleSlefInfo: false,
      visiblePW: false,
      // 数据默认字段
      defaultProps: undefined,
      // 数据列表
      loadingBtn: false,
      options: [],
      sysList: [],
      time: moment().format('yyyy/MM/DD HH:mm'),
      weather: {
        province: '',
        city: '',
        district: '',
        adcode: '',
        weather: '',
        temperature: '',
        winddirection: '',
        windpower: '',
        humidity: '',
        reporttime: ''
      }
    }
  },
  created() {
    this.getImg()
    this.getCurrPigFarms()
    this.getSysList()
    this.getWeather()
  },
  mounted() {
    let that = this
    setInterval(function() {
      that.time = moment().format('yyyy/MM/DD HH:mm')
    }, 1000)
  },
  methods: {
    getImg() {
      const userId = localStorage.getItem('userId')
      this.$http.get(this.$api.getUserInfo, { userId: userId }).then(res => {
        if (res.result) {
          this.imageUrl2 = res.result.headUrl
          this.userName = res.result.name
        }
      })
    },
    handleSucess(res, file, fileList) {
      this.imageUrl = res.data
    },
    handleUploadFile(params) {
      let that = this
      let formData = new FormData()
      formData.append('file', params.file)
      axios
        .post('/upms/User/uploadHeadPic', formData)
        .then(function(response) {
          that.imageUrl = response.result.headUrl
        })
        .catch(function(error) {
          that.$message.error(error.message)
        })
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePreview(file) {
      console.log(file)
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    submitForm(formName) {
      let that = this
      this.loadingBtn = true
      this.$refs[formName].validate(valid => {
        if (valid) {
          loginApi
            .modifyPwd({
              oldPassword: this.pwForm.passWord,
              password: this.pwForm.pass
            })
            .then(res => {
              if (res.code === 200) {
                this.loadingBtn = false
                this.$message.success('修改成功，请重新登录')
                this.handleCommand('logout')
              } else {
                this.$message.error(res.message)
              }
            })
            .catch(res => {
              this.loadingBtn = false
              this.$message.error('修改失败')
            })
        } else {
          return false
        }
      })
    },
    changeSys(val) {
      // this.$http.get(this.$api.ssoRedirect, {
      //   url: val
      // }).then(res => {
      //   window.open(res.message)
      // })
      let url = val + '?token=' + localStorage.getItem('token')

      window.open(url)
    },
    updateUserInfo() {
      this.visibleSlefInfo = false
      const userId = localStorage.getItem('userId')

      loginApi
        .updateUserInfo({
          headUrl: this.imageUrl,
          userId: userId,
          linkPohone: this.ruleForm.linkPohone,
          nikeName: this.ruleForm.nikeName,
          userMail: this.ruleForm.userMail
        })
        .then(res => {
          if (res.code == 200) {
            this.$message.success('修改成功')
            this.getImg()
          }
        })
    },
    getIcon() {
      let weather = this.weather.weather
      if (weather) {
        if (weather.search('云') != -1) {
          return require('../../assets/img/weather/dy.png')
        } else if (weather.search('雾') != -1) {
          return require('../../assets/img/weather/dww.png')
        } else if (weather.search('风') != -1) {
          return require('../../assets/img/weather/df.png')
        } else if (weather.search('冷') != -1) {
          return require('../../assets/img/weather/dw.png')
        } else if (weather.search('热') != -1) {
          return require('../../assets/img/weather/gw.png')
        } else if (weather.search('阴') != -1) {
          return require('../../assets/img/weather/dy.png')
        } else if (weather == '龙卷风') {
          return require('../../assets/img/weather/ljf.png')
        } else if (weather.search('晴') != -1) {
          return require('../../assets/img/weather/qt.png')
        } else if (weather.search('尘') != -1) {
          return require('../../assets/img/weather/scb.png')
        } else if (weather.search('霾') != -1) {
          return require('../../assets/img/weather/wm.png')
        } else if (weather.search('雪') != -1) {
          return require('../../assets/img/weather/xx.png')
        } else if (weather.search('雨') != -1) {
          if (weather == '雨夹雪') {
            return require('../../assets/img/weather/yjx.png')
          } else {
            return require('../../assets/img/weather/xy.png')
          }
        }
      }
    },
    collapseMenu() {
      this.$store.commit('changeMenu')
    },

    getSysList() {
      // this.$http.get(this.$api.getSysList, {}).then(res => {
      //   if (res.result) {
      //     this.sysList = res.result.records
      //   }
      // })
      this.$http.post(this.$api.getSysListAll, {}).then(res => {
        if (res.result) {
          this.sysList = res.result
        }
      })
    },

    getCurrPigFarms(e) {
      let that = this
      loginApi.getCurrPigFarms().then(res => {
        let arr = res.result
        if (arr) {
          arr.map(item => {
            that.options.push({
              label: item.regionName,
              value: item.regionId
            })
          })
          let farmId = store.state.farmId
          console.log('farmId')
          console.log(sessionStorage.getItem('farmId'))
          if (farmId == null || farmId == '') {
            console.log(1)
            if (sessionStorage.getItem('farmId')) {
              this.$store.commit('setFarmId', sessionStorage.getItem('farmId'))
              this.defaultProps = sessionStorage.getItem('farmId')
            } else {
              this.$store.commit('setFarmId', res.result[0].regionId)
              this.defaultProps = res.result[0].regionId
            }
          } else {
            console.log(2)
            console.log(farmId)
            console.log(sessionStorage.getItem('farmId'))
            if (sessionStorage.getItem('farmId').length > 0) {
              if (sessionStorage.getItem('farmId') != JSON.parse(sessionStorage.getItem('store')).farmId) {
                this.$store.commit('setFarmId', JSON.parse(sessionStorage.getItem('store')).farmId)
                this.defaultProps = JSON.parse(sessionStorage.getItem('store')).farmId
                sessionStorage.setItem('farmId', JSON.parse(sessionStorage.getItem('store')).farmId)
              }
              // this.$store.commit('setFarmId', sessionStorage.getItem('farmId'))
              // this.defaultProps = sessionStorage.getItem('farmId')
            } else {
              if (res.result.findIndex(item => item.regionId === farmId) === -1) {
                console.log(111)
                this.defaultProps = res.result[0].regionId
              } else {
                console.log(222)
                this.defaultProps = farmId
              }
              // this.defaultProps = farmId
            }
          }
        }
      })
    },
    //切换猪场
    changeFarm(e) {
      this.$store.commit('setFarmId', e)
      this.reload()
    },
    getWeather() {
      this.$http
        .get(this.$api.getWeather, {
          regionId: store.state.farmId
        })
        .then(res => {
          if (res.result) {
            this.weather = res.result
          }
        })
    },
    // 退出方法
    handleCommand(command) {
      if (command === 'logout') {
        this.$store.commit('setFarmId', '')
        this.clearTabs()
        sessionStorage.clear()
        sessionStorage.removeItem('tabs')
        if (window.__POWERED_BY_QIANKUN__) {
          localStorage.removeItem('token')
          localStorage.removeItem('nikeName')
          localStorage.removeItem('refreshToken')
          let hrefs = window.location.href.split('swaq/')[0] + '#/login'
          window.location.href = hrefs
        } else {
          localStorage.removeItem('token')
          localStorage.removeItem('nikeName')
          localStorage.removeItem('refreshToken')
          this.$router.push('/login')
          // 'http://192.168.200.13:9080/client/sys/getsysInfo',
          // 'http://yozhu.cn/client/sys/getsysInfo',

          //生产
          // axios(
          //   'post',
          //   'http://yozhu.cn/client/sys/getsysInfo',
          //   // 'http://unify.hsfxm.cn/client/sys/getsysInfo',
          //   {sysCode:'IoTLogin'},
          // ).then(res=>{
          //   let url = res.data.data.sysLoginPath
          //   window.location.href = url
          // })

          //开发
          this.$router.push({ path: '/login' })
        }
      } else if (command === 'slef_info') {
        this.ruleForm = {}
        const userId = localStorage.getItem('userId')
        this.$http.get(this.$api.getUserInfo, { userId: userId }).then(res => {
          this.ruleForm = res.result
          this.imageUrl = res.result.headUrl
        })
        this.visibleSlefInfo = true
      } else if (command === 'edit_pw') {
        // const linkPohone = localStorage.getItem('linkPohone')
        // this.phoneNumber = linkPohone
        this.visiblePW = true
      }
    },
    clearTabs() {
      let arr = []
      loginApi.getMenusBySysCode('iot').then(res => {
        if (res.code === 200) {
          if (res.result) {
            res.result.map(item => {
              if (item.children) {
                item.children.map(x => {
                  arr.push(x)
                })
              } else {
                arr.push(item)
              }
            })
          }
          if (arr) {
            arr.map(item => {
              this.$store.commit('closeTab', item)
            })
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.avatar-uploader {
  width: 150px;
  border: 1px dashed black;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}
.avatar {
  width: 150px;
  height: 150px;
  display: block;
}

header {
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: space-between;
  .el-icon-s-fold,
  .el-icon-s-unfold {
    font-size: 25px;
    cursor: pointer;
  }
}

.borderradius {
  display: inline-block;
  border-radius: 100px;
  height: 40px;
  width: 40px;
  overflow: hidden;
  line-height: 50px;
  border: 1px solid #eee;
  img {
    width: 40px;
  }
}

.opt-wrapper {
  display: flex;
  align-items: center;
}

.other {
  color: #606266;
}

//
//.avatar {
//  margin-top: 10px;
//  // display: block;
//  margin-right: 40px;
//  height: 150px;
//  width: 150px;
//  border-radius: 50%;
//  cursor: pointer;
//}

.weather {
  width: 24px;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2a2a37;
  line-height: 17px;
  margin-left: 8px;
}
.temperature {
  width: 37px;
  color: rgba(53, 138, 255, 1);
  height: 22px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 22px;
}
.city {
  margin-left: 3px;
  height: 20px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2a2a37;
  line-height: 20px;
}
::v-deep .el-select .el-input .el-input__inner {
  border: unset !important;
  text-align: right !important;
}
</style>
