import FileSaver from "file-saver";
import XLSX from "xlsx";
import moment from 'moment'
//定义导出Excel表格事件
export const exportExcel = (id) =>{
  /* 从表生成工作簿对象 */
  let wb = XLSX.utils.table_to_book(document.querySelector("#"+id));
  /* 获取二进制字符串作为输出 */
  let wbout = XLSX.write(wb, {
    bookType: "xlsx",
    bookSST: true,
    type: "array"
  });
  console.log(wbout)
  try {
    FileSaver.saveAs(
      //Blob 对象表示一个不可变、原始数据的类文件对象。
      //Blob 表示的不一定是JavaScript原生格式的数据。
      //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
      //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
      new Blob([wbout], { type: "application/octet-stream" }),
      //设置导出文件名称
      getExcelName(id)?getExcelName(id):'表格.xlsx'
    );
  } catch (e) {
    if (typeof console !== "undefined") console.log(e, wbout);
  }
  return wbout;
}

export const getExcelName = (tableId) => {
  if (tableId=='waterList') return '水表设备列表.xlsx'
  if (tableId=='waterLog')  return '水表历史记录.xlsx'
  if (tableId=='eleList')   return '电表设备列表.xlsx'
  if (tableId=='eleLog')    return '电表历史记录.xlsx'
  if (tableId=='foodList')  return '料塔设备列表.xlsx'
  if (tableId=='foodLog')   return '料塔历史记录.xlsx'
  if (tableId=='videoList') return '视频盘点设备列表.xlsx'
  if (tableId=='videoLog')  return '视频盘点历史记录.xlsx'
  if (tableId=='pigSaleList') return '卖猪盘点盘点历史记录.xlsx'
  if (tableId=='pigSaleLog')  return '卖猪盘点历史记录.xlsx'

}

export const forEach = (arr, fn) => {
  if (!arr.length || !fn) return
  let i = -1
  let len = arr.length
  while (++i < len) {
    let item = arr[i]
    fn(item, i, arr)
  }
}

/**
 * @param {Array} arr1
 * @param {Array} arr2
 * @description 得到两个数组的交集, 两个数组的元素为数值或字符串
 */
export const getIntersection = (arr1, arr2) => {
  let len = Math.min(arr1.length, arr2.length)
  let i = -1
  let res = []
  while (++i < len) {
    const item = arr2[i]
    if (arr1.indexOf(item) > -1) res.push(item)
  }
  return res
}

/**
 * @param {Array} arr1
 * @param {Array} arr2
 * @description 得到两个数组的并集, 两个数组的元素为数值或字符串
 */
export const getUnion = (arr1, arr2) => {
  return Array.from(new Set([...arr1, ...arr2]))
}

/**
 * @param {Array} target 目标数组
 * @param {Array} arr 需要查询的数组
 * @description 判断要查询的数组是否至少有一个元素包含在目标数组中
 */
export const hasOneOf = (targetarr, arr) => {
  return targetarr.some(_ => arr.indexOf(_) > -1)
}

/**
 * @param {String|Number} value 要验证的字符串或数值
 * @param {*} validList 用来验证的列表
 */
export function oneOf (value, validList) {
  for (let i = 0; i < validList.length; i++) {
    if (value === validList[i]) {
      return true
    }
  }
  return false
}

/**
 * @param {Number} timeStamp 判断时间戳格式是否是毫秒
 * @returns {Boolean}
 */
const isMillisecond = timeStamp => {
  const timeStr = String(timeStamp)
  return timeStr.length > 10
}

/**
 * @param {Number} timeStamp 传入的时间戳
 * @param {Number} currentTime 当前时间时间戳
 * @returns {Boolean} 传入的时间戳是否早于当前时间戳
 */
const isEarly = (timeStamp, currentTime) => {
  return timeStamp < currentTime
}

/**
 * @param {Number} num 数值
 * @returns {String} 处理后的字符串
 * @description 如果传入的数值小于10，即位数只有1位，则在前面补充0
 */
const getHandledValue = num => {
  return num < 10 ? '0' + num : num
}

/**
 * @param {Number} timeStamp 传入的时间戳
 * @param {Number} startType 要返回的时间字符串的格式类型，传入'year'则返回年开头的完整时间
 */
const getDate = (timeStamp, startType) => {
  const d = new Date(timeStamp * 1000)
  const year = d.getFullYear()
  const month = getHandledValue(d.getMonth() + 1)
  const date = getHandledValue(d.getDate())
  const hours = getHandledValue(d.getHours())
  const minutes = getHandledValue(d.getMinutes())
  const second = getHandledValue(d.getSeconds())
  let resStr = ''
  if (startType === 'year') resStr = year + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + second
  else resStr = month + '-' + date + ' ' + hours + ':' + minutes
  return resStr
}

/**
 * @param {String|Number} timeStamp 时间戳
 * @returns {String} 相对时间字符串
 */
export const getRelativeTime = timeStamp => {
  // 判断当前传入的时间戳是秒格式还是毫秒
  const IS_MILLISECOND = isMillisecond(timeStamp)
  // 如果是毫秒格式则转为秒格式
  if (IS_MILLISECOND) Math.floor(timeStamp /= 1000)
  // 传入的时间戳可以是数值或字符串类型，这里统一转为数值类型
  timeStamp = Number(timeStamp)
  // 获取当前时间时间戳
  const currentTime = Math.floor(Date.parse(new Date()) / 1000)
  // 判断传入时间戳是否早于当前时间戳
  const IS_EARLY = isEarly(timeStamp, currentTime)
  // 获取两个时间戳差值
  let diff = currentTime - timeStamp
  // 如果IS_EARLY为false则差值取反
  if (!IS_EARLY) diff = -diff
  let resStr = ''
  const dirStr = IS_EARLY ? '前' : '后'
  // 少于等于59秒
  if (diff <= 59) resStr = diff + '秒' + dirStr
  // 多于59秒，少于等于59分钟59秒
  else if (diff > 59 && diff <= 3599) resStr = Math.floor(diff / 60) + '分钟' + dirStr
  // 多于59分钟59秒，少于等于23小时59分钟59秒
  else if (diff > 3599 && diff <= 86399) resStr = Math.floor(diff / 3600) + '小时' + dirStr
  // 多于23小时59分钟59秒，少于等于29天59分钟59秒
  else if (diff > 86399 && diff <= 2623859) resStr = Math.floor(diff / 86400) + '天' + dirStr
  // 多于29天59分钟59秒，少于364天23小时59分钟59秒，且传入的时间戳早于当前
  else if (diff > 2623859 && diff <= 31567859 && IS_EARLY) resStr = getDate(timeStamp)
  else resStr = getDate(timeStamp, 'year')
  return resStr
}

/**
 * @returns {String} 当前浏览器名称
 */
export const getExplorer = () => {
  const ua = window.navigator.userAgent
  const isExplorer = (exp) => {
    return ua.indexOf(exp) > -1
  }
  if (isExplorer('MSIE')) return 'IE'
  else if (isExplorer('Firefox')) return 'Firefox'
  else if (isExplorer('Chrome')) return 'Chrome'
  else if (isExplorer('Opera')) return 'Opera'
  else if (isExplorer('Safari')) return 'Safari'
}

/**
 * @description 绑定事件 on(element, event, handler)
 */
export const on = (function () {
  if (document.addEventListener) {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.addEventListener(event, handler, false)
      }
    }
  } else {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.attachEvent('on' + event, handler)
      }
    }
  }
})()

/**
 * @description 解绑事件 off(element, event, handler)
 */
export const off = (function () {
  if (document.removeEventListener) {
    return function (element, event, handler) {
      if (element && event) {
        element.removeEventListener(event, handler, false)
      }
    }
  } else {
    return function (element, event, handler) {
      if (element && event) {
        element.detachEvent('on' + event, handler)
      }
    }
  }
})()

/**
 * 判断一个对象是否存在key，如果传入第二个参数key，则是判断这个obj对象是否存在key这个属性
 * 如果没有传入key这个参数，则判断obj对象是否有键值对
 */
export const hasKey = (obj, key) => {
  if (key) return key in obj
  else {
    let keysArr = Object.keys(obj)
    return keysArr.length
  }
}

/**
 * @param {*} obj1 对象
 * @param {*} obj2 对象
 * @description 判断两个对象是否相等，这两个对象的值只能是数字或字符串
 */
export const objEqual = (obj1, obj2) => {
  const keysArr1 = Object.keys(obj1)
  const keysArr2 = Object.keys(obj2)
  if (keysArr1.length !== keysArr2.length) return false
  else if (keysArr1.length === 0 && keysArr2.length === 0) return true
  /* eslint-disable-next-line */
  else return !keysArr1.some(key => obj1[key] != obj2[key])
}

/**
 * @param {*} obj1 对象
 * @description 判断对象是否的有个属性的值为空
 */
export const checkEmptyJSON = (obj) => {
  if (obj) {
    return Object.keys(obj).some((item, index) => {
      return obj[item] === ''
    })
  } else {
    return false
  }
}


export const TreeDataSource = (arr) => {
  //if(!Array.isArray(Array)){ return; }
  return arr.map((item,i)=>{
    if(item.children){
      return {
        // 'path':item.path,
        // 'name':item.lablePath.split("/")[1]?item.lablePath.split("/")[1]:item.lablePath?item.lablePath:item.lableName,
        // 'label':item.lableName,
        // 'icon':item.lableIcon,
        // 'children':TreeDataSource(item.children)

        // 'path':item.lablePath,
        // 'name':item.lablePath.split("/")[1]?item.lablePath.split("/")[1]:item.lablePath?item.lablePath:item.lableName,
        // 'label':item.lableName,
        // 'icon':item.lableIcon,
        // 'children':TreeDataSource(item.children)
      }
    }else{
      return {
        // 'path':item.lablePath,
        // 'name':item.lablePath.split("/")[1]?item.lablePath.split("/")[1]:item.lablePath?item.lablePath:item.lableName,
        // 'label':item.lableName,
        // 'icon':item.lableIcon
      }
    }
  })
}

export const changeKey = (data) =>{
  return data.map((item,i)=>{
    if(item.children){
      return{
        'parentId':item.fatherId,
        'value':item.id,
        'label':item.name,
        'classs':item.classs,
        'router':item.router,
        'target':item.target,
        'type':item.type,
        'url':item.url,
        'children':changeKey(item.children)
      }
    }else{
      return{
        'parentId':item.fatherId,
        'value':item.id,
        'label':item.name,
        'classs':item.classs,
        'router':item.router,
        'target':item.target,
        'type':item.type,
        'url':item.url,
      }
    }
  })
}

/**
 * 创建唯一的字符串
 * @return {string} ojgdvbvaua40
 */
 export const createUniqueString =  ()=> {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

// elementui日期时间范围 快捷选项
export const pickerOptionsRange = {
  disabledDate:(time)=> {
    if (this.pickerMinDate) {
      const day1 =  1095 * 24 * 3600 * 1000
      let maxTime = ''
      let minTime = ''
      if (Date.now() - this.pickerMinDate > day1){
        maxTime = this.pickerMinDate + day1
        minTime = this.pickerMinDate - day1
        return time.getTime() > maxTime || time.getTime()<minTime || time.getTime() > Date.now()
      }else {
        maxTime = Date.now()
        minTime = this.pickerMinDate - day1
        return time.getTime() > maxTime || time.getTime()<minTime || time.getTime() > Date.now()
      }
    }else{
      return time.getTime() > Date.now()
    }
  },
  onPick:(obj) =>{
    this.pickerMinDate = new Date(obj.minDate).getTime();
  },
  shortcuts: [
    {
      text: '今天',
      onClick (picker) {
        const end = new Date()
        const start = new Date(new Date().toDateString())
        start.setTime(start.getTime())
        picker.$emit('pick', [start, end])
      }
    }, {
      text: '最近一周',
      onClick (picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(end.getTime() - 3600 * 1000 * 24 * 7)
        picker.$emit('pick', [start, end])
      }
    }, {
      text: '最近十五天',
      onClick (picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 15)
        picker.$emit('pick', [start, end])
      }
    }, {
      text: '最近一个月',
      onClick (picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
        picker.$emit('pick', [start, end])
      }
    }, {
      text: '最近三个月',
      onClick (picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
        picker.$emit('pick', [start, end])
      }
    }
  ]
}

// elementui月份范围 快捷选项
export const pickerOptionsRangeMonth = {
  shortcuts: [
    {
      text: '今年至今',
      onClick (picker) {
        const end = new Date()
        const start = new Date(new Date().getFullYear(), 0)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: '最近半年',
      onClick (picker) {
        const end = new Date()
        const start = new Date()
        start.setMonth(start.getMonth() - 6)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: '最近一年',
      onClick (picker) {
        const end = new Date()
        const start = new Date()
        start.setMonth(start.getMonth() - 12)
        picker.$emit('pick', [start, end])
      }
    }
  ]
}

export const TreeDataOrg = (arr) => {
  return arr.map((item,i)=>{
    if(item.children){
      return {
        'parentId':item.fatherId,
        'value':item.id,
        'label':item.name,
        'children':TreeDataSource(item.children)
      }
    }else{
      return {
        'parentId':item.fatherId,
        'value':item.id,
        'label':item.name
      }
    }
  })
}

export const tranBuildType = (arr) => {
  if (arr==0) return '临时栏'
  if (arr==1) return '怀孕大栏'
  if (arr==2) return '产保舍'
  if (arr==3) return '公猪舍'
  if (arr==4) return '配种舍'
  if (arr==5) return '妊娠舍'
  if (arr==6) return '育肥舍'
  if (arr==7) return '隔离舍'
  if (arr==8) return '后备舍'
  if (arr==9) return '分娩舍'
  if (arr==10) return '保育舍'
}

export const numberTran = (arr) => {
  // if (arr>1000) {
  //   let item  = '0'
  //   if (Math.floor(arr%1000)<100&&Math.floor(arr%1000)>10){
  //     item = item + '' + Math.floor(arr%1000 * 10)/10
  //   }else  if (Math.floor(arr%1000)<10){
  //     item = 0 + '' + 0 + '' + Math.floor(arr%1000 *10)/10
  //   }else {
  //     item = Math.floor(arr%1000 * 10)/10
  //   }
  //   return Math.floor(arr/1000) +','+ item
  // }else {
  //   return arr
  // }
  if (arr) {
    let str = arr.toString()
    let reg = str.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g
    return str.replace(reg, '$1,')
  } else {
    return arr
  }
}

