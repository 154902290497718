<template>
  <div class='sidebar'>
    <div class="imglong" v-show="!isCollapse" style="text-align:center;padding:0;">
      <img :src="logoUrl" width="190px" height="40px" style="margin:0 auto;padding:5px" alt="" />
    </div>
    <!-- <div class='imglong imgbox' v-show='!isCollapse&&logoPath==undefined' @click='changeMenu({name: "首页",path: "/home",target: "home"})'>
      <img src='../../../assets/logo.png' width='173px' height='30px' alt=''>
    </div>
    <div class='imglong imgbox' v-show='!isCollapse&&logoPath!=undefined' @click='changeMenu({name: "首页",path: "/home",target: "home"})'>
      <img :src='logoPath' width='173px' height='30px' alt=''>
    </div>
    <div class='imgshort imgbox' v-show='isCollapse'>
      <img src='../../../assets/logo2.jpg' alt=''>
    </div> -->

    <div class='menulist'>
      <el-menu  text-color='#282828' active-text-color='#2F7ECF'
               :collapse='isCollapse' :unique-opened='true' class='el-menu-vertical-demo'>
        <!-- 没有儿子的导航栏 -->
        <el-menu-item :index='item.id+""' v-for='item in noChildren' :key='item.path' @click='clickMenu(item)'>
          <i class="iconfont fontsize" v-html="item.icon"></i>
          <span slot='title'>{{ item.name }}</span>
        </el-menu-item>
        <!-- 有儿子的导航栏 -->
        <el-submenu :index='item.id+""' v-for='item in hasChildren' :key='item.id'>
          <template slot='title'>
            <i class="iconfont fontsize" v-html="item.icon"></i>
            <span>{{ item.name }}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item :index='subItem.id+""' v-for='subItem in item.children' :key='subItem.id'
                          @click='clickMenu(subItem)'>
              <i class="iconfont fontsize" v-html="subItem.icon"></i>
              <span slot='title'>{{ subItem.name }}</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>
<script>
import MenuData from './menu_data.js'
import logo from '../../../assets/logo.png'
import logo3 from '../../../assets/logo4.png'
import { TreeDataSource } from '../../libs/tools.js'
import loginApi from '../../api/user/login'

export default {
  data() {
    return {
      // logoUrl:logo3,
      logoUrl:logo,
      logoPath:undefined,
      asideMenu: [

      ],
      noChildren: [],
      hasChildren: []
    }
  },
  created() {
    loginApi.getMenuList().then(res=>{
        this.asideMenu = res.result
        this.noChildren = this.asideMenu.filter(item => !item.children)
        this.hasChildren = this.asideMenu.filter(item => item.children);
    })
  },
  mounted() {
    this.getWeather()
    this.getLogo();
    let _this = this
    // this.asideMenu = MenuData.get_menu_data();
  },
  computed: {

    // 判断导航栏是否有子节点
    // noChildren() {
    //   return this.asideMenu.filter(item => !item.children)
    // },
    // hasChildren() {
    //   return this.asideMenu.filter(item => item.children)
    // },
    isCollapse() {
      return this.$store.state.menu.isCollapse
    },
    // 设置当前激活的导航
    activeNav() {
      return this.$route.path
    }
  },
  methods: {

    getLogo() {
      loginApi.getRegion().then(res => {

          if (res.code == 200) {
            if(res.result.logoPath){
              this.logoUrl= res.result.logoPath;
            }

          } else {
            this.$message.error(res.message)
          }
        }).catch(err => {
          this.$message.error('系统异常，请联系管理员！')
        })
    },
    getWeather() {
      // this.$http.get(this.$api.getWeather, {}).then(res => {
      //   if (res.result) {
      //     this.logoPath = res.data.logoPath
      //   }
      // })
    },

    // 点击切换页面方法
    changeMenu(item) {
      this.$router.push({ name: item.target })
      this.$store.commit('selectMenu', item)
    },

    // 点击切换路由
    clickMenu(item) {
      // this.$router.push({ name: item.target })

      localStorage.setItem('routename',item.name)
      localStorage.setItem('routetarget',item.target)
      this.$store.commit('selectMenu', item)
      if(this.$store.state.tab.isNewTab){
        this.$router.push({ name:item.target,params:{refresh:true} })
      }else{
        this.$router.push({ name:item.target,params:{refresh:false} })
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.fontsize{
  font-size: 20px;
  display: inline-block;
  margin-right:8px;
}
.el-menu {
  height: 100%;
  border: none;
}

.sidebar {
  border-right: 1px solid #eee;
  //padding-right: 5px;
  background-color: #fff;
  height: 100%;
  overflow-y: hidden;

  .menulist {
    height: calc(100vh - 61px);
    overflow-y: auto;
    scrollbar-width: none;

  }

  .imgbox {
    padding: 14px 5px 12px 10px;
    background: #fff;
    border-bottom: 1px solid #C8C8C8;
  }

  .imgshort {
    img {
      width: 50px;
    }
  }

  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }

  .el-menu-vertical-demo {
    .is-active {
      //background: #2F7ECF !important;
      //border-radius: 6px;
    }
  }

  .el-menu-item.is-active{
    background-color: #fff ;
  }

  .el-submenu.is-active .el-submenu__title {
    background-color: #fff ;
  }
  .el-submenu .el-menu-item:hover {
    background-color: #ecf5ff!important;
  }

  .el-submenu .el-submenu__title:hover {
    color: #FFF;
  }
  .menulist::-webkit-scrollbar {
    display: none
  }
}
</style>
