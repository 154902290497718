<template>
  <div style='background-color: white;padding: 10px;border-radius: 8px;height: calc(100vh - 132px);overflow-y: hidden'>
    <searchForm :formOptions='formOptions' @onSearch='onSearch' :btnItems='btnItems' @onExport='onExport' />
<!--    <div class='title'>-->
<!--      共  <span style='color:black'>{{ totalDevice }}</span> 条,-->
<!--      在线 <span style='color:black'>{{ onlineDevice }}</span>,-->
<!--      离线 <span style='color:red'>{{offlineDevice}}</span>-->
<!--    </div>-->
    <page-table
      v-if="hardReset"
      v-loading='loading'
      :tableData='tableData'
      :tableLabel='tableLabel'
      :tableOption='tableOption'
      :tableVideo='tableVideo'
      class="gdtable"
      :toTop='toTop'
      :pager='pager'
      @pagination='handleCurrentChange'
      @handleSizeChange='handleSizeChange'
      @handleButton='tableBtnHandle(arguments)'
      @changeTop='changeTop'
      @cancelTop='cancelTop'
    ></page-table>

    <el-dialog
      title='视频播放'
      :visible.sync='editVisible'
      width='50%'
    >
      <VideoPlayer v-if='editVisible' :urlVideo='urlVideo'></VideoPlayer>

<!--      <video id="exampleVideo" width='100%' height='100%' poster="../../assets/img/video.png" controls autoplay :src="urlVideo">您的设备不支持video</video>-->
    </el-dialog>
  </div>
</template>

<script>
import pageTable from '@/components/pageTable'
import searchForm from '@/components/searchForm'
import videoModel from './videoModel'
import login from '../../api/user/login'
import { exportExcel, tranBuildType } from '../../libs/tools'
import store from '../../store'
import VideoPlayer from '../../components/VideoPlayer'
import axios from "axios";
import {getDownload} from "../../libs/axios";

export default {
  name: 'pigSaleList',
  components: {
    pageTable,
    searchForm,
    VideoPlayer,
    videoModel
  },
  data() {
    return {
      loading: true,
      hardReset:true,
      totalDevice: 0,
      onlineDevice: 0,
      offlineDevice: 0,
      obj: undefined,
      urlVideo: '',
      editVisible: false,
      btnItems: 'search' + 'export' + 'reset',
      formOptions: [
        {
          label: '过道名称',
          prop: 'passagewayName',
          element: 'el-input',
          placeholder: '请输入设备名称'
        }
      ],
      tableData: [],
      tableLabel: [
        { label: '位置', param: 'passagewayPosition' },
        { label: '负责人', param: 'controlAccount' },
        { label: '过道名称', param: 'passagewayName' },
        { label: '称重称', param: 'weightName' }
      ],
      tableOption: {
        label: '摄像头',
        options: [
          { label: '视频', methods: 'video' ,videoName:"videoName"}
        ]
      },
      tableVideo: {

      },
      toTop: {
        label: '置顶',
        options: [
          { label: '详情', methods: 'editDetail' }
        ]
      },
      pager: {
        pageSize: 10,  // 每页数据量
        currentPage: 1, // 分页  当前页
        total: 0  //列表总数
      }
    }
  },
  mounted() {
    this.getPigSaleList()
    this.getStatusCount()
    this.getAllPigpen()
  },
  methods: {
    //设备列表
    getPigSaleList(obj) {
      let that = this
      this.$http.get(this.$api.pigSaleList, {
        ...obj,
        deviceType: '销售称重秤',
        current: that.pager.currentPage,
        size: that.pager.pageSize
      }).then(res => {
        let arr = res.data.records
        if (arr) {
          arr.map(item => {
            if (item.online == 1) {
              item.online = '在线'
            } else {
              item.online = '离线'
            }
          })
        }
        this.tableData = arr
        this.pager.total = res.data.total
        this.loading = false
        this.hardReset = true
      })
    },
    //卖猪盘点设备状态
    getStatusCount(obj) {
      let that = this
      this.$http.get(this.$api.getStatusCount, {
        ...obj,
        deviceType: '销售称重秤'
      }).then(res => {
        if (res.data) {
          that.offlineDevice = res.data.offline
          that.onlineDevice = res.data.online
          that.totalDevice = res.data.total
        }
      })
    },
    //栋舍列表
    getAllPigpen() {
      let arr = []
      this.$http.get(this.$api.getAllPigpen, {
        deviceType: '销售称重秤'
      }).then(res => {
        if (res.data) {
          res.data.map(item => {
            arr.push({
              label: item.name,
              value: item.name
            })
          })
        }
      })
      return arr
    },
    //单元类型列表
    getBuildType() {
      let arr = []
      this.$http.get(this.$api.getBuildType, {
        deviceType: '销售称重秤'
      }).then(res => {
        if (res.data) {
          res.data.map(item => {
            console.log(item)
            arr.push({
              label: tranBuildType(item),
              value: item
            })
          })
        }
      })
      return arr
    },
    //翻页
    handleCurrentChange(val) {
      this.pager.currentPage = val
      this.loadTable(this.obj)
    },
    //页码大小
    handleSizeChange(val) {
      this.pager.pageSize = val
      this.loadTable(this.obj)
    },
    changeTop(val) {
      this.$http.patch(this.$api.changeTop(val), {}).then(res => {
      })
    },
    cancelTop(val) {
      this.$http.patch(this.$api.cancelTop(val), {}).then(res => {
      })
    },
    tableBtnHandle(arg) {
      if (arg[0] == 'video') {
        this.editVisible = true
        this.urlVideo = arg[1].url
      }
    },
    onSearch(val) {
      this.$set(this.pager,'currentPage',1)
      this.hardReset = false
      this.obj = val
      this.loadTable(val)
    },
    //重载表格
    loadTable(val) {
      this.hardReset = false
      this.loading = true
      this.getPigSaleList(val)
      this.getStatusCount(val)
    },
    onExport(val) {
      this.obj = val
      this.loadTable(val)
      this.exportWaterList(val)
    },
    //列表导出
    exportWaterList(obj) {
      // let urls = store.state.baseUrl + this.$api.exportPigSaleList + '?deviceType=卖猪盘点&current=-1&size=-1&farmId=' + store.state.farmId
      // let passagewayName = ''
      // if (obj.passagewayName) {
      //   passagewayName = obj.passagewayName
      //   urls = urls + '&passagewayName=' + passagewayName
      // }
      // window.location.href = urls
      axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('token');
      let headers = {
        'x-auth-token': localStorage.getItem('token')
      }
      getDownload(this.$api.exportPigSaleList,{
        farmId:store.state.farmId,
        ...obj
      },headers).then((res) => {
        let blob = new Blob([res.data])
        let downloadElement = document.createElement('a')
        let href = window.URL.createObjectURL(blob); //创建下载的链接
        downloadElement.href = href;
        // downloadElement.download = decodeURIComponent(res.headers.filename); //下载后文件名
        downloadElement.download = `卖猪盘点设备列表.xlsx`; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放blob对象
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .gdtable .el-table__body-wrapper {
    height: calc(100vh - 296px) !important;
    overflow-y: auto !important;
}
.title {
  margin-bottom: 10px;
  color: #606266;

}
</style>
