import { render, staticRenderFns } from "./foodDeviceList.vue?vue&type=template&id=33875c20&scoped=true&"
import script from "./foodDeviceList.vue?vue&type=script&lang=js&"
export * from "./foodDeviceList.vue?vue&type=script&lang=js&"
import style0 from "./foodDeviceList.vue?vue&type=style&index=0&id=33875c20&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.10.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33875c20",
  null
  
)

export default component.exports