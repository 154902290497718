import { TreeDataSource } from '../../libs/tools.js'

import store from '../../store'

import axios from '../../api'
let menudata = {
  path: '/home',
  name: 'home',
  label: '首页',
  icon: 's-platform'
};
export default {
  get_menu_data: () => {
    let newMenu = JSON.parse(localStorage.getItem('menu'));
    if(!localStorage.getItem('menu')){
      axios('get', '/plat/menu/getMenuByUser').then(res => {
        if (res.statusCode === 200) {
          newMenu  = res.data;
          let newData = TreeDataSource(newMenu);
          newData.unshift(menudata);
          //window.location.reload()
          return newData;
        }
      })
    }else{
      let newData = TreeDataSource(newMenu);
      newData.unshift(menudata);
      return newData;
    }
  }
}
