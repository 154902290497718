export default{
  getAllMenuTree:'/iot/Menu/getAllMenuTree',//获取菜单get
  updateMenuTree:'/iot/Menu/update',//修改菜单patch
  addMenuTree:'/iot/Menu/add',//新增菜单post
  getMenuById(id){
    return  `/iot/Menu/${id}`//根据id获取菜单get
  },
  delMenuById(id){
    return  `/iot/Menu/${id}`//根据id获取菜单delete
  },
  getEnviPage:'/iot/Environmentaltactics/page',//报警策略列表
  getEnvironmentaltactics(id){
    return  `/iot/Environmentaltactics/${id}`//根据id获取策略
  },
  updateEnvironmentaltactics:'/iot/Environmentaltactics/update',//修改保存策略
  addEnvironmentaltactics:'/iot/Environmentaltactics/add',//新增保存策略
  delEnvironmentaltactics(id){
    return  `/iot/Environmentaltactics/${id}`//根据id获取策略
  },
  getDeviceStrategyPage:'/iot/Device/strategyPage',//分页查询设备绑定列表/get
  getDevicePage:'/iot/Device/page',//分页查询设备绑定列表/get
  getDeviceTypeList:'/iot/Device/getStrategyDeviceType',//查询所有设备类型/get/farmId
  getPigpenBuild:'/iot/Pigpen/getBuild',//当前猪场所有栋舍列表/get/farmId
  getEnvironmentaltacticsList:'/iot/Environmentaltactics/list',//查询所有绑定策略
  bindEnvironmentaltactics:'/iot/Environmentaltactics/bind',//绑定策略
  getPigpenFiled:'/iot/Pigpen/getFiled',//根据栋舍id查栏位数组
  getExceptionrecordList:'/iot/Exceptionrecord/page',//查询日志列表
  setDeviceBuildOrField:'/iot/Device/bind',//绑定栋舍栏位
  getPassageway:'/iot/Passageway/typeList',//获取通道列表
  addPassageway:'/iot/Passageway/add',//新增通道
  getPassagewaylistByDeviceId:'/iot/Passageway/listByDeviceId',//设备环控通道查询
  updatePassageway:'/iot/Passageway/update',//修改通道
  getFeedEvent:'/iot/FeedEvent/page',//料塔事件管理接口
  SysDictItem:'/system/SysDictItem/list',//查询字典表
  delPassageway(id){
    return  `/iot/Passageway/${id}`//根据id获取策略
  },
  getPigSalePassageway:'/iot/PigSalePassageway/page',//分页查询卖猪秤列表
  addPigSalePassageway:'/iot/PigSalePassageway/add',//新增卖猪秤
  updatePigSalePassageway:'/iot/PigSalePassageway/update',//修改卖猪秤
  delPigSalePassageway(id){
    return  `/iot/PigSalePassageway/${id}`//根据id获取策略
  },
  pushDataBat:'/iot/Device/pushDataBat',//推送组播

  addRepair:'/iot/Repair/add',//设备报修

  delDevice(id){
    return  `/iot/Device/${id}`//根据id删除设备
  },
  getMoneyrecordpage:'/iot/Moneyrecord/page',//获取增值服务列表
  getPigBuildList:'upms/PigBuild/getList',//新版 区,栏 下拉列表
  getUnbindRealBuilds:'build/PigBuild/getUnbindRealBuilds',//获取未绑定水电栋舍列表
  addReadingData:'iot/used/addReadingData',//新增电
}
