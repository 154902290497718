/**
* 表单匹配项
*/
<template>
  <div class='form-item'>
    <el-input
      v-if='isInput'
      v-model='currentVal'
      v-bind='bindProps'
      v-on='bindEvents'
      size='mini'
    ></el-input>

    <el-input-number
      v-if='isInputNumber'
      v-model='currentVal'
      v-bind='bindProps'
      v-on='bindEvents'
      :controls-position="itemOptions['controls-position'] || 'right'"
      size='mini'
    ></el-input-number>

    <el-select
      v-if='isSelect'
      v-model='currentVal'
      v-bind='bindProps'
      v-on='bindEvents'
      :multiple='itemOptions.multiple==1'
      :collapse-tags='itemOptions.multiple==1'
      size='mini'
      filterable
      clearable
      :style='{width: itemOptions.multiple==1?160+"px":135+"px"}'
      @change="((event)=>{changeVal(event, itemOptions.prop)})"
    >
      <el-option
        v-for='item in itemOptions.options'
        :key='item.value'
        :label='item.label'
        :value='item.value'
      ></el-option>
    </el-select>

    <el-radio-group
      style='width: 280px'
      v-if='isRadioGroup'
      v-model='currentVal'
      v-bind='bindProps'
      v-on='bindEvents'
      size='mini'
    >
      <el-radio-button
        v-for='item in itemOptions.options'
        :key='item.value'
        :disabled='item.disabled'
        :label='item.label'
        :value='item.value'
      ></el-radio-button>
    </el-radio-group>


    <!-- datetimerange/daterange -->
    <el-date-picker
      v-if='isDatePickerDateRange'
      v-model='currentVal'
      v-bind='bindProps'
      v-on='bindEvents'
      :type="itemOptions.type || 'datetimerange'"
      size='mini'
      :picker-options='pickerOptionsRange'
      start-placeholder='开始日期'
      range-separator='至'
      end-placeholder='结束日期'
      value-format='yyyy-MM-dd'
    ></el-date-picker>

    <!-- monthrange -->
    <el-date-picker
      v-if='isDatePickerMonthRange'
      v-model='currentVal'
      v-bind='bindProps'
      v-on='bindEvents'
      type='monthrange'
      size='mini'
      :picker-options='pickerOptionsRangeMonth'
      start-placeholder='开始日期'
      range-separator='至'
      end-placeholder='结束日期'
      value-format='yyyy-MM'
    ></el-date-picker>

    <!-- others -->
    <el-date-picker
      v-if='isDatePickerOthers'
      v-model='currentVal'
      v-bind='bindProps'
      v-on='bindEvents'
      type='date'
      size='mini'
      :picker-options="pickerOptions"
      placeholder='请选择日期'
    ></el-date-picker>

    <el-cascader
      v-if='isCascader'
      v-model='currentVal'
      v-bind='bindProps'
      v-on='bindEvents'
      size='mini'
      clearable
    ></el-cascader>
  </div>
</template>

<script>
import { pickerOptionsRange, pickerOptionsRangeMonth } from '@/libs/tools'
export default {
  inheritAttrs: false,

  props: {
    value: {},
    itemOptions: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  data() {
    return {
      pickerMinDate:'',
      pickerOptionsRange: {
        disabledDate:(time)=> {
          if (this.pickerMinDate) {
            const day1 =  1095 * 24 * 3600 * 1000
            let maxTime = ''
            let minTime = ''
            if (Date.now() - this.pickerMinDate > day1){
              maxTime = this.pickerMinDate + day1
              minTime = this.pickerMinDate - day1
              return time.getTime() > maxTime || time.getTime()<minTime || time.getTime() > Date.now()
            }else {
              maxTime = Date.now()
              minTime = this.pickerMinDate - day1
              return time.getTime() > maxTime || time.getTime()<minTime || time.getTime() > Date.now()
            }
          }else{
            return time.getTime() > Date.now()
          }

        },
        onPick:(obj) =>{
          this.pickerMinDate = new Date(obj.minDate).getTime();
        },
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              const end = new Date()
              const start = new Date(new Date().toDateString())
              start.setTime(start.getTime())
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近十五天',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      pickerOptionsRangeMonth: pickerOptionsRangeMonth,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
    }
  },

  computed: {
    // 双向绑定数据值
    currentVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    // 绑定属性
    bindProps() {
      let obj = { ...this.itemOptions }
      // 移除冗余属性
      delete obj.label
      delete obj.prop
      delete obj.element
      delete obj.initValue
      delete obj.rules
      delete obj.events
      if (obj.element === 'el-select') {
        delete obj.options
      }
      return obj
    },
    // 绑定方法
    bindEvents() {
      return this.itemOptions.events || {}
    },
    // el-input
    isInput() {
      return this.itemOptions.element === 'el-input'
    },
    // el-input-number
    isInputNumber() {
      return this.itemOptions.element === 'el-input-number'
    },
    // el-select
    isSelect() {
      return this.itemOptions.element === 'el-select'
    },
    isRadioGroup() {
      return this.itemOptions.element === 'el-radio-group'
    },

    // el-date-picker (type: datetimerange/daterange)
    isDatePickerDateRange() {
      const isDatePicker = this.itemOptions.element === 'el-date-picker'
      const isDateRange = !this.itemOptions.type ||
        this.itemOptions.type === 'datetimerange' ||
        this.itemOptions.type === 'daterange'
      return isDatePicker && isDateRange
    },
    // el-date-picker (type: monthrange)
    isDatePickerMonthRange() {
      const isDatePicker = this.itemOptions.element === 'el-date-picker'
      const isMonthRange = this.itemOptions.type === 'monthrange'
      return isDatePicker && isMonthRange
    },
    //  el-date-picker (type: date)
    isDatePickerOthers() {
      const isDatePicker = this.itemOptions.element === 'el-date-picker'
      return isDatePicker && !this.isDatePickerDateRange && !this.isDatePickerMonthRange
    },
    // el-cascader
    isCascader() {
      return this.itemOptions.element === 'el-cascader'
    }
  },

  created() {
  },

  methods: {
    changeVal(val,prop) {
      if (this.itemOptions.isChange == 'change') {
        this.$emit('getSelectId', val,prop)
      }else if (this.itemOptions.isChange == 'change2') {
        this.$emit('getSelectId2', val)
      }
    }
  },

  components: {}
}
</script>

<style scoped>

.el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
  width: 280px;
}

>>> .el-date-editor .el-range-separator {
  padding: 0 5px;
  line-height: 20px;
  width: unset;
  color: #303133;
}
</style>
