import axios from 'axios'
import router from '../router'
import store from '../store'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// axios.defaults.headers['x-auth-token'] = localStorage.getItem('token')
axios.defaults.withCredentials = true
axios.defaults.crossDomain = true

const manageAxios = axios.create({
  baseURL: store.state.baseUrl, // 服务网关地址
  withCredentials: true
})


class HttpRequest {
  constructor(baseUrl) {

    this.baseUrl = baseUrl
    this.queue = {}
  }

  getInsideConfig() {
    const config = {
      baseURL: this.baseUrl,
      headers: {
        //
      },
      withCredentials: true,
      crossDomain: true
    }

    return config
  }

  destroy(url) {
    delete this.queue[url]
    if (!Object.keys(this.queue).length) {
      // Spin.hide()
    }
  }

  interceptors(instance, url) {
    // 请求拦截
    instance.interceptors.request.use(config => {
      // 通过拦截request请求,主动为 请求头,追加新属性 Authorization,等于 token 值
    if (localStorage.getItem('token')) {
      // config.headers['x-auth-token'] = localStorage.getItem('token');
    }
      // 添加全局的loading...
      if (!Object.keys(this.queue).length) {
        // Spin.show() // 不建议开启，因为界面不友好
      }
      this.queue[url] = true
      return config
    }, error => {
      return Promise.reject(error)
    })
    // 响应拦截
    instance.interceptors.response.use(res => {
      this.destroy(url)
      const { data } = res
      if (data.code !== 20000) {
        // Message.error(data.message)
      }
      if (data.code === '304') {
        router.push({ name: 'login' })
      }
      return data
    }, error => {
      this.destroy(url)
      return Promise.reject(error)
    })
  }

  request(options) {
    const instance = axios.create()
    instance.defaults.withCredentials = true
    instance.defaults.crossDomain = true
    options = Object.assign(this.getInsideConfig(), options)
    this.interceptors(instance, options.url)
    return instance(options)
  }
}


export function getDownload(url,params,headers) {
  if (!headers) {
    headers = {};
  }
  const isConteType = headers['Content-Type'];
  if (!isConteType) {
    headers['Content-Type'] = 'application/json';
  }
  return manageAxios({
    url: url,
    params: params,
    headers: headers,
    method: 'get',
    responseType: 'blob'
  })
}

export default HttpRequest
