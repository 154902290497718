import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from '../api'

import api from '../api/user/login'

import apiList from '../api/apiList'
import Login from '../views/Login.vue'
import Main from '../views/Main.vue'
import Home from '../views/home/index_new.vue'
import Setting from '../views/setting/index.vue'
import audit from '../views/audit'
import foodTowerDeviceList from '../views/setting/foodDeviceList'
import store from '../store'
import { parse } from 'qs'


/***
 * 在升级了Vue-Router版本到到3.1.0及以上之后，页面在跳转路由控制台会报Uncaught (in promise)的问题，
 * 在3.1.0版本里面新增功能：push和replace方法会返回一个promise, 你可能在控制台看到未捕获的异常。
 * 解决办法
 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

let _this = this
Vue.use(VueRouter)
let prefix = '/'
let routes = []
if (window.__POWERED_BY_QIANKUN__) {
  prefix = '/iot/'

}
  routes = [
    {
      path: prefix + 'login',
      name: 'login',
      component: Login
    },
    {
      path: prefix,
      name: 'Main',
      redirect: prefix + 'home',
      component: Main,
      children: [
        {
          path: prefix + 'home',
          name: 'home',
          component: Home
        },
        {
          path: prefix + 'setting',
          name: 'setting',
          component: Setting,
        },
        {
          path: prefix + 'user',
          name: 'user',
          component: () => import('@/views/user/index')
        },
        {
          path: prefix + 'setting/messageLog',
          name: 'messageLog',
          component: () => import('@/views/setting/messageLog')
        }
      ]
    },
    {
      path: prefix + 'about',
      name: 'About',
      component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  ]
let menuList =[];

const router = new VueRouter({
  base: window.__POWERED_BY_QIANKUN__ ? '/iot/' : '/',
  mode: 'hash',
  routes
})

router.beforeEach((to, from, next) => {
  // debugger;
  if(to.params.refresh){
    to.meta.keepAlive = false;
  }else{
    to.meta.keepAlive = true;
  }
  window.document.title = to.meta.title == undefined?'物联环控':to.meta.title
  const token = localStorage.getItem('token');
  const IS_PROD = process.env.NODE_ENV ==='development';
  if (!token) {
    // 开发

    if(IS_PROD){
      console.log("开发环境")
      if (to.path==='/login'){
        next()
      }else {
        next('/login')
      }
    }else{
      console.log("生产环境")
      console.log(store.state.baseUrl)
      axios(
      'post',
        // 'http://yozhu.cn/client/sys/getsysInfo',
        // 'http://unify.hsfxm.cn/client/sys/getsysInfo',
        store.state.baseUrl+'/upms/UnifySmSysInfo/sys/getsysInfo',

        {sysCode:'IoTLogin'},
      ).then(res=>{

        let url = res.result.sysLoginPath
        window.location.href = url
      })
    }
    // window.location.href = "http://192.168.200.13:9080/#/IoTLogin"
    // window.location.href = "http://192.168.200.13/situweb/#/home"

    //生产

  } else {
    if(!sessionStorage.getItem('menu')){
      console.log(111)
      getMenuList();
    }else{
      let routerarr = JSON.parse(sessionStorage.getItem('menu'))
      if(routes[1].children.length<=4){
        setRoute(routerarr)
        menuList.map((item)=>{
          routes[1].children.push(item)
        })
        router.addRoutes(routes);
        let tmpname = to.path.split('/')[2]?to.path.split('/')[2]:to.path;
        if(localStorage.getItem('routename')){
          store.commit('selectMenu', {target:localStorage.getItem('routetarget'),name:localStorage.getItem('routename')})
        }
        next({name:tmpname})
      }else{
        let tmpname = to.path.split('/')[2]?to.path.split('/')[2]:to.path;
        if(tmpname == '/'){
          next({name:'home'})
        }else{
          next()
        }
      }
    }
   next()
  }

})

function setRoute(data){
  data.map((item)=>{
    if(item.router){
      menuList.push({
          'path': prefix+item.router,
          'name': item.target,
          'component':  resolve => require([`@/views/${item.router}.vue`], resolve)
      })
    }
    if(item.children){
      setRoute(item.children)
    }
  })
}

function getMenuList() {
  // debugger;
  menuList=[];

  api.getMenusBySysCode('IoTLogin').then(res=>{
    if (res.code === 200) {
      sessionStorage.setItem('menu',JSON.stringify(res.result))
      store.commit('getMenu',res.result)
      setRoute(res.result)
      menuList.map((item)=>{
        routes[1].children.push(item)
      })
      router.addRoutes(routes);
    }
  })

}


export default router
