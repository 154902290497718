import axios from '@/libs/api.request.js'
import apiList from '@/api/apiList.js'

/**
 * @param {api}    请求API地址
 * @param {param}  追加参数
 * @param {body}  表单数据
 * @param {method}  请求方式
 * @param {headType} 请求头配置
 */

const request = (method, api, data, params) => {
  let url = ''
  if (typeof apiList[api] === 'undefined'){
    url = api
  }else{
    url = apiList[api]
  }
  return axios.request({
    url,
    data,
    params,
    method,
  })
  
}
export default request
