<template>
  <div style='height: calc(100vh - 132px);overflow-y: scroll'>
    <el-row class='fullHeight'>
      <el-col :span='19' class='fullHeight'>
        <el-row :gutter='10'>
          <el-col :span='12' class='fullHeight'>
            <el-card class='box-card tabs_box' style='min-height:225px;width: auto;padding-bottom:10px;'>
              <div slot='header' class='clearfix card-title'>
                <el-tabs v-model='activeName'
                         @tab-click='handleClick'
                         style='width: 70%;display: inline-block'>
                  <el-tab-pane label='耗料情况' name='third'></el-tab-pane>
                  <el-tab-pane label='用电情况' name='first'></el-tab-pane>
                  <el-tab-pane label='用水情况' name='second'></el-tab-pane>
                </el-tabs>

                <el-button v-if="activeName=='first'" style='float: right; padding: 3px 0;color: rgba(0, 0, 0, 0.4);margin-top: 4px;' type='text'
                           @click='goto("setting/electricLog","电表历史记录","electricLog")'>
                  查看更多<i class='el-icon-arrow-right'></i>
                </el-button>
                <el-button v-if="activeName=='second'" style='float: right; padding: 3px 0;color: rgba(0, 0, 0, 0.4);margin-top: 4px;' type='text'
                           @click='goto("setting/waterLog","水表历史记录","waterLog")'>
                  查看更多<i class='el-icon-arrow-right'></i>
                </el-button>
                <el-button v-if="activeName=='third'" style='float: right; padding: 3px 0;color: rgba(0, 0, 0, 0.4);margin-top: 4px;' type='text'
                           @click='goto("setting/foodDeviceList","料塔设备列表","foodDeviceList")'>
                  查看更多<i class='el-icon-arrow-right'></i>
                </el-button>
              </div>
              <div v-if="activeName=='first'" style='max-height: 275px;overflow-y: auto;overflow-x: hidden;'>
                <div v-for='(item,index) in eleList' :key='item.deviceId?item.deviceId:index'
                     style='width: 50%;display: inline-block;border-bottom: 1px solid #DCE0E9;padding-bottom: 8px'>
                  <div class='flex-col'>
                    <div class='tabs_title'>{{ item.buildName }}</div>
                    <div class='tabs_device'>{{ item.deviceName }}</div>
                    <div class='main20 flex-row'>
                      <div class='bd24 flex-col iconfont' v-html="'&#xe60c;'"></div>
                      <span class='word69'>{{ item.amount | dataFormat() }}</span>
                      <span class='word72' style='' v-if='item.amount'>度</span>
                      <span class='txt44'>24h用电</span>
                      <span class='word71'>{{ item.amountCount != 0.0 ? item.amountCount : '--' }}</span>
                      <span class='word72' style='font-size: 10px; color: rgba(42, 42, 55, 0.6);'
                            v-if='item.amountCount!=0.0'>度</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="activeName=='second'" style='max-height: 280px;overflow-y: auto;overflow-x: hidden;'>
                <div v-for='(item,index) in waterList' :key='item.deviceId?item.deviceId:index'
                     style='width: 50%;display: inline-block;border-bottom: 1px solid #DCE0E9'>
                  <div class='flex-col'>
                    <div class='tabs_title'>{{ item.buildName }}</div>
                    <div class='tabs_device'>{{ item.deviceName }}</div>
                    <div class='main20 flex-row'>
                      <div class='bd24 flex-col iconfont' v-html="'&#xe60c;'"></div>
                      <span class='word69'>{{ item.amount | dataFormat() }}</span>
                      <span class='word72' style='' v-if='item.amount'>m³</span>
                      <span class='txt44'>24h用水</span>
                      <span class='word71'>{{ item.amountCount != 0.0 ? item.amountCount : '--' }}</span>
                      <span class='word72' style='font-size: 10px; color: rgba(42, 42, 55, 0.6);'
                            v-if='item.amountCount!=0.0'>m³</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="activeName=='third'" style='max-height: 280px;overflow-y: auto;overflow-x: hidden;'>
                <div v-for='(item,index) in foodList' :key='item.deviceId?item.deviceId:index'
                     style='width: 50%;display: inline-block;border-bottom: 1px solid #DCE0E9'>
                  <div class='flex-col'>
                    <div class='tabs_title'>{{ item.buildName }}</div>
                    <div class='tabs_device'>{{ item.deviceName }}</div>
                    <div class='main20 flex-row'>
                      <div class='block9 flex-row'>
                        <span class='word133'>余</span>
                        <span class='info53'>{{ item.residue?item.residue:'--'}}</span>
                        <span class='info54'>kg</span>
                        <span class='txt64'>，</span>
                        <span class='info55'>日均耗料</span>
                        <span class='word134'>{{ item.avgAmount!=0.0?item.avgAmount:'--' }}</span>
                        <span class='word135'>kg</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-card>
            <el-card class='box-card' style='min-height:225px;width: auto;margin-top:10px'>
              <div slot='header' class='clearfix card-title'>
                <span style='font-size: 16px'><i class='iconfont' v-html="'&#xe62a;'"></i> 关注事项</span>
                <el-button style='float: right; padding: 3px 0;color: rgba(0, 0, 0, 0.4)' type='text'
                           @click='goto(" follow/followRecord","关注记录","followRecord")'>
                  查看更多<i class='el-icon-arrow-right'></i>
                </el-button>
              </div>
              <div class='main10 flex-col'>
                <div class='outer9 flex-row' v-for='item in followEventData' :key='item.id'>
                  <div class='flex-col' style='width: 100%'>
                    <div class='flex-row' style='align-items: center;width: 100%'>
                      <span class='event_title'>{{item.buildName}}{{ item.deviceName }}</span>
                      <span class='event_desc' style='margin-left: 8px'>{{ item.desc }}</span>
                      <span class='word44' style='margin-left: auto'>{{ item.statusDate | dateFormat }}</span>
                    </div>
                    <span class='flex-row device_name' style='align-items: center'>
                      <img src='../../assets/img/device.png'>
                      <span style='margin-left: 5px'>{{ item.buildName }}&nbsp;</span>
                    </span>
                    <div class='flex-row' style='align-items: center;width: 100%;margin-top: 4px'>
                      <span class='event_info' style=';padding: 0 4px'>{{ item.continued }}</span>
                      <span class='event_info event_box'>{{ item.value }}</span>
                      <span class='event_status event_box' v-if='item.status==2'>已恢复</span>
                      <span class='event_status_ing event_box' v-if='item.status==0||item.status==1'>持续中</span>
                    </div>
                  </div>
                </div>
                <el-empty v-if='followEventData.length<1' :image-size='40'></el-empty>
              </div>
            </el-card>
          </el-col>
          <el-col :span='12' class='fullHeight'>
            <el-card class='box-card' style='margin-bottom:10px;width: auto'  v-if='eleWarn=="true"'>
              <div slot='header' class='clearfix card-title'>
                <span style='font-size: 16px;'><i class='iconfont' v-html="'&#xe70d;'"></i> 断电告警</span>
                <el-button style='float: right; padding: 3px 0; color: rgba(0, 0, 0, 0.4)' type='text'
                           @click='goto("setting/electricWarnLog","历史记录","electricWarnLog")'>
                  查看更多<i class='el-icon-arrow-right'></i></el-button>
              </div>
              <div class='main21 flex-col'>
                <el-carousel height="80px">
                  <el-carousel-item v-for='(item,index) in eleWarnData' :key='index'>
                    <div class='eleWarn' >
                      <div style='display: flex;flex-direction: column'>
                        <div style='display: flex;margin-top: 8px;margin-bottom: 4px'>
                          <span class='word211' style='color: rgba(0, 0, 0, 0.8);'>{{ item.statusDate | dateFormat }}</span>
                          <span class='info2'>{{ item.deviceName }}</span>
                        </div>
                        <div style='display: flex;margin-bottom:8px;'>
                          <div
                            style='background: #F5F5F5;border-radius: 2px;font-size: 12px;color: #71717d;padding: 3px 3px'>
                            {{ item.continued }}
                          </div>
                          <div v-if='item.status==0||item.status==1'
                               style='margin-left:8px;background:rgba(188, 77, 77, 0.2);border-radius: 2px;font-size: 12px;color: #BC4D4D;padding: 3px 3px'>
                            断电中
                          </div>
                          <div v-if='item.status==2'
                               style='margin-left:8px;  background: rgba(11, 136, 95, 0.1);border-radius: 2px;font-size: 12px;color: rgba(11, 136, 95,1);padding: 3px 3px'>
                            已恢复
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
                <el-empty v-if='eleWarnData.length<1' :image-size='40'></el-empty>
              </div>
            </el-card>
            <el-card class='box-card evn' style='min-height:250px;margin-bottom:10px;width: auto' v-if='evn=="true"'>
              <div slot='header' class='clearfix card-title3'>
                <span style='font-size: 16px'>
                  <i class='iconfont' v-html="'&#xe60d;'"></i>
                  实时环控
                </span>
                <el-button style='float: right; padding: 3px 0;color: rgba(0, 0, 0, 0.4)' type='text'
                           @click='goto("setting/evnDeviceList","环控设备列表","evnDeviceList")'>
                  查看更多<i class='el-icon-arrow-right'></i>
                </el-button>
              </div>
              <div v-infinite-scroll='load' :infinite-scroll-disabled='disabled'>
                <div v-for='(item,index) in evnData' :key='index' @click='toDeviceList(item)'>
                  <div v-if='item.arrLength>2'>
                    <div class='flex-row' style='margin-left: 16px'>
                      <span class='word17'>{{ item.deviceName }}</span>
                      <div :class='colorOfType(3,item.buildType)'>
                        <span class='word18'>{{ item.buildTypeName }}</span>
                      </div>
                    </div>
                  </div>
                  <div class='layer8 flex-row '>
                    <div class='flex-row' style='margin-right: 16px' v-if='item.arrLength<=2'>
                      <span class='word17'>{{ item.deviceName }}</span>
                      <div :class='colorOfType(3,item.buildType)'>
                        <span class='word18'>{{ item.buildTypeName }}</span>
                      </div>
                    </div>
                    <div class='wrap4' v-if='item.temperature'>
                      <span class='info8'>{{ item.temperature.passValue }}</span>
                      <span class='word19'>℃</span>
                      <span class='word-c19'>{{ item.temperature.passName }}</span>
                    </div>
                    <div class='wrap5' v-if='item.humidity'>
                      <span class='word20'>{{ item.humidity.passValue }}</span>
                      <span class='word21'>%RH</span>
                      <span class='word-c21'>{{ item.humidity.passName }}</span>
                    </div>
                    <div class='wrap6' v-if='item.ammonia'>
                      <div>
                        <span class='word22'>{{ item.ammonia.passValue }}</span>
                        <span class='txt18'>ppm</span>
                      </div>
                      <span class='txt18'>{{ item.ammonia.passName }}</span>
                    </div>
                    <el-tooltip placement='top' v-if='item.fan'>
                      <template slot='content'>{{ item.fan.passValue }}</template>
                      <div class='wrap7 flex-col' v-if='item.fan&&item.fan.passValue!="--"'>
                        <i class='iconfont icon2' v-html="'&#xe9ed;'" v-if='item.fan.passValue=="关闭"'></i>
                        <i class='iconfont icon2-on' v-html="'&#xe9ed;'" v-if='item.fan.passValue=="开启"'></i>
                        <span class='info9'>{{ item.fan.passName }}</span>
                      </div>
                    </el-tooltip>
                    <el-tooltip placement='top' v-if='item.curtain'>
                      <template slot='content'>{{ item.curtain.passValue }}</template>
                      <div class='wrap8 flex-col' v-if='item.curtain&&item.curtain.passValue!="--"'>
                        <i class='iconfont label6' v-html="'&#xe9ee;'" v-if='item.curtain.passValue=="关闭"'></i>
                        <i class='iconfont label6-on' v-html="'&#xe9ee;'" v-if='item.curtain.passValue=="开启"'></i>
                        <span class='txt19'>{{ item.curtain.passName }}</span>
                      </div>
                    </el-tooltip>

                    <i class='iconfont label7 ' style='float: right' v-html="'&#xe600;'"
                       @click='toDeviceList(item)'></i>
                  </div>
                </div>
                <p v-if='loading' style='text-align: center;color: rgba(60, 66, 73, 0.8);'>加载中...</p>
                <p v-if='noMore' style='text-align: center;color: rgba(60, 66, 73, 0.8);'>没有更多了</p>
              </div>
              <el-empty v-if='evnData.length<1' :image-size='40'></el-empty>
            </el-card>
            <el-card class='box-card' style='min-height:200px;margin-bottom:10px;width: auto' v-if='pigSale=="true"'>
              <div slot='header' class='clearfix card-title'>
                <span style='font-size: 16px'><i class='iconfont' v-html="'&#xe715;'"></i> 卖猪盘点</span>
                <el-button style='float: right; padding: 3px 0;color: rgba(0, 0, 0, 0.4)' type='text'
                           @click='goto("setting/pigSaleLog","历史记录","pigSaleLog")'
                >查看更多<i class='el-icon-arrow-right'></i>
                </el-button>
              </div>
              <div class='main21 flex-col'>
                <div style='display: flex'>
                  <div style='width: 55%; display: flex;flex-direction: column'>
                    <div style='margin-top: 14px;display: flex;align-items: center'>
                      <span class='pigging' v-if='!pigSaleOne.endDate'>卖猪中</span>
                      <span>
                         {{ pigSaleOne.passagewayName }}{{ pigSaleOne.buildTypeName }}卖猪视频
                      </span>
                    </div>
                    <div>
                      <span class='words'>保育猪</span>
                      <span class='words' style='margin-left: 8px'>总重{{ pigSaleOne.weight  }}kg</span>
                      <span class='words' style='margin-left: 8px'>{{ pigSaleOne.createDate | dateFormat }}</span>
                    </div>
                    <div style='margin-top: 10px;margin-bottom: 17px'>
                      <video width='90%' height='100%'
                             class='pig_video'
                             controls='controls' autoplay
                             :src='pigSaleOne.url'>您的设备不支持video
                      </video>
                    </div>
                  </div>
                  <div style='width: 45%;margin-top:14px;margin-bottom: 17px'>
                    <span>卖猪列表</span>
                    <div class='pigList' style='height: 195px;overflow-y: scroll;  scrollbar-width: none;'>
                      <div v-for='(item,index) in pigSaleData' class='infinite-list-item ' :key='item.id'>
                        <div class='flex-row' v-if='index>1'>
                          <video width='75px' height='55px'
                                 class='pig_vp'
                                 style=''
                                 autoplay
                                 @click='showModel(item.url,item)'
                                 :src='item.url'>您的设备不支持video
                          </video>
                          <div @click='showModel(item.url,item)'
                               class='pig_desc'
                               style=''>
                            <span style='padding-top: 4px'>{{ item.passagewayName }}{{ item.type }}</span>
                            <span>卖猪视频</span>
                            <br/>
                            <span class='words'> {{pigSaleOne.createDate | dateFormat}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <el-empty v-if='pigSaleData.length<1' :image-size='40'></el-empty>
              </div>
            </el-card>
          </el-col>
<!--          <el-col :span='12' class='fullHeight'>-->
<!--          </el-col>-->
        </el-row>
      </el-col>
      <el-col :span='5' class='fullHeight' style='padding:0 0 10px 10px' >
        <el-card class='box-card' style='min-height:100px;width: auto;'  >
<!--          <avue-data-display :option="option"></avue-data-display>-->
<!--          @click='goto("setting/seo","环控设备列表","seo")'-->
          <div >
            <avue-data-box :option="option" ></avue-data-box>
          </div>
        </el-card>
        <el-card class='box-card ' style='height: 275px;width: auto;margin-top: 10px'>
          <div slot='header' class='clearfix'>
            <span style='font-size: 16px'><i class='iconfont' v-html="'&#xe639;'"></i> 我的收藏</span>
          </div>
          <div style='height: 300px;overflow-y: scroll;scrollbar-width: none;padding: 4px' class='scroll_hidden'>
            <div class='card' v-for='(item,index) in collectionList' :key='index'>
              <el-card shadow='hover'>
                <div slot='header' class='clearfix'>
                  <span><i class='iconfont' v-html="'&#xe626;'" style='color: #358AFF'></i></span>
                  <span style='float: right;opacity: 0'
                        @click='deleteCollection(item.id)'
                  >
                  <i class='delete iconfont' v-html="'&#xe777;'" style='color: #B74105'></i></span>
                </div>
                <div class='card-main' @click='goto(item.router,item.name,item.target)'>
                  {{ item.name }}
                </div>
              </el-card>
            </div>
          </div>
        </el-card>
        <el-card class='box-card' style='min-height:240px;width: auto;margin-top: 10px'>
          <div slot='header' class='clearfix card-title2'>
            <span style='font-size: 16px'><i class='iconfont' v-html="'&#xe611;'"></i> 分享沟通</span>
            <el-button style='float: right; padding: 3px 0;color: rgba(0, 0, 0, 0.4)' type='text'
                       @click='goto("setting/messageLog","历史消息","messageLog")'
            >查看更多<i class='el-icon-arrow-right'></i>
            </el-button>
          </div>
          <div class='main25 flex-col'>
            <div class='box29 flex-col'>
<!--              <div class='outer16 flex-col'></div>-->
              <div class='outer17 flex-col'>
                <div v-for='(item,index) in messageData' :key='index'>
                  <div>
                     <span class='info49' v-if='index>0&&!((item.createdTime.substring(0,10))==(messageData[index-1].createdTime.substring(0,10)))'>
                      {{item.createdTime | dateFormat   }}
                    </span>
                    <span class='info49' v-if='index==0'>{{ item.createdTime | dateFormat }}</span>
                  </div>
                  <div class='main26 flex-row'>
                    <span class='word121' v-if='index>0&&(!item.account==messageData[index-1].account)'>{{ item.account }}</span>
                    <span class='word121' v-if='index==0'>{{ item.account }}</span>
                  </div>
                  <div class='main27 flex-row' style='border-radius: 4px;'>
                      <span class='txt58'>
                        {{ item.content }}
                      </span>
                  </div>
                </div>
              </div>
              <div style='padding: 0 16px;display: flex;border-top:1px solid #D3D7DF;'>
                <el-input style='margin-top: 12px;' size='small' v-model='messageForm.content'></el-input>
                <el-button type='primary' size='small' style='margin: 12px 0 12px 0;float: right'
                           @click='addCommunication'>
                  发送
                </el-button>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
<!--    <el-dialog-->
<!--      title="详情"-->
<!--      :visible.sync="deviceVisible"-->
<!--      width="50%">-->
<!--      <el-descriptions class="margin-top" :column="2" :size="size" border>-->
<!--        <el-descriptions-item>-->

<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item>-->

<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item>-->

<!--        </el-descriptions-item>-->
<!--      </el-descriptions>-->
<!--      <span slot="footer" class="dialog-footer">-->
<!--        <el-button @click="deviceVisible = false">取 消</el-button>-->
<!--        <el-button type="primary" @click="deviceVisible = false">确 定</el-button>-->
<!--      </span>-->
<!--    </el-dialog>-->
    <el-dialog
      title='视频播放'
      :visible.sync='editVisible'
      width='50%'
    >
      <div style='padding:10px 10px;background-color: black;color: white;text-align: center'>
        <span style='color: white;float: left'>{{videoItem.passagewayName}}</span>
        <span style='padding:0px 15px'>猪只类型: {{ videoItem.type}}</span>
        <span style='padding:0px 15px'>头数: {{ videoItem.count}}头</span>
        <span style='padding:0px 15px'>重量: {{ videoItem.weight}}kg</span>
<!--        <span style='padding:0px 15px'>{{ videoItem.createDate}}</span>-->
      </div>
      <video id='exampleVideo' width='100%' height='100%' controls='controls' autoplay
             :src='urlVideo'>您的设备不支持video
      </video>
    </el-dialog>

  </div>
</template>
<script>
import { tranBuildType } from '../../libs/tools'
import other from '../../api/other/other'
import store from '../../store'
import Template from '../setting/template'
import moment from 'moment'
import pigSaleList from '../setting/pigSaleList'

export default {
  components: { Template },
  inject: ['reload'],
  data() {
    return {
      loading: false,
      evn: false,
      water: false,
      ele: false,
      foot: false,
      eleWarn: false,
      pigSale: false,
      urlVideo: '',
      videoItem: {},
      editVisible: false,
      farmId: store.state.farmId,
      pigSum: 4,
      count: 4,
      datax: [
        {
          index: 1,
          is: false
        }, {
          index: 2,
          is: false
        }, {
          index: 3,
          is: true
        },
        {
          index: 4,
          is: true
        }
      ],
      eleWarnData: [
      //   {
      //   deviceName:'122栋',
      //   statusDate:'2021-02-10 10:58:55',
      //   status:2,
      //   continued:'持续10分钟'
      // },{
      //   deviceName:'1212栋',
      //   statusDate:'2021-02-10 10:58:55',
      //   status:1,
      //   continued:'持续10分钟'
      // }
      ],
      activeName: 'third',
      evnData: [],
      evnList: [],
      waterList: [],
      waterCount: 0,
      eleList: [],
      eleCount: 0,
      foodList: [],
      foodCount: 0,
      pigSaleOne: {},
      pigSaleData: [],
      messageData: [],
      messageForm: {
        content: '',
        sysCode: 'iot',
        regionId: store.state.farmId
      },
      collectionList: [],
      followEventData: [],
      online:0,
      offline:0,
      deviceCount:0,
      deviceVisible:false,
      option: {
        span:8,
        data: [
          {
            color: 'rgb(42, 42, 55)',
            count: 0,
            title: '设备总数',
          },
          {
            color: 'rgb(11, 136, 95)',
            count: 0,
            title: '在线设备',
          },
          {
            color: 'rgb(188, 77, 77)',
            count: 0,
            title: '离线设备',
          },

        ]
      },
    }
  },
  computed: {
    isFollow() {
      return this.$store.state.farmId　　//需要监听的数据
    },
    noMore() {
      return this.count >= this.evnData.length
    },
    disabled() {
      return this.loading || this.noMore
    }
  },
  watch: {
    isFollow(newVal, oldVal) {
      this.init()
    }
  },
  created() {
  },
  mounted() {
    if (store.state.farmId != '') {
      this.init()
    }
  },
  filters: {
    dataFormat(msg) {
      if (msg == null) {
        return '--'
      }
      if (msg > 1000) {
        let item = '0'
        if (Math.floor(msg % 1000) < 100) {
          item = item + '' + Math.floor(msg % 1000)
        } else {
          item = Math.floor(msg % 1000)
        }
        return Math.floor(msg / 1000) + ' ' + item
      } else {
        return msg
      }
    },
    dateFormat(msg) {
      let day = moment(new Date()).diff(moment(msg), 'd')
      let year = moment(new Date()).diff(moment(msg), 'y')
      if (day < 1) {
        let hour = moment(msg).format('HH')
        if (moment(msg).format('HH') > 12) {
          return '下午 ' + moment(msg).format('HH:mm')
        } else {
          return '上午 ' + moment(msg).format('HH:mm')
        }
      } else {
        if (day <= 2) {
          return '昨天 ' + moment(msg).format('HH:mm')
        }
        if (day >= 2 && year <= 0) {
          return moment(msg).format('MM/DD HH:mm')
        }
        if (year > 0) {
          return moment(msg).format('yyyy/MM/DD HH:mm')
        }
      }
    }
  },
  methods: {
    showDevice(){
      this.deviceVisible = true
    },
    load() {
      this.loading = true
      setTimeout(() => {
        this.count += 2
        this.loading = false
      }, 2000)
    },
    handleClick(tab, event) {
      console.log(tab.index)
      this.activeName = tab.name
    },
    //列表权限
    getAppInfo() {
      this.$http.get(this.$api.getAppInfo, {}).then(res => {
        // console.log(res.data)
        if (res.data) {
          res.data.map(item => {
            if (item.appName == '实时环控') {
              this.evn = item.isOpen
            } else if (item.appName == '水量计') {
              this.water = item.isOpen
            } else if (item.appName == '电表') {
              this.ele = item.isOpen
            } else if (item.appName == '料塔') {
              this.foot = item.isOpen
            } else if (item.appName == '断电报警') {
              this.eleWarn = item.isOpen
            } else if (item.appName == '卖猪盘点') {
              this.pigSale = item.isOpen
            }
          })
        }
      })
    },
    toDeviceList(item) {
      this.$router.push({
        name: 'evnDeviceList',
        query: {
          deviceName: item.deviceName
        }
      })
      localStorage.setItem('routename', 'evnDeviceList')
      localStorage.setItem('routetarget', 'evnDeviceList')
      this.$store.commit('selectMenu', {
        router: 'setting/evnDeviceList',
        name: '环控设备列表',
        target: 'evnDeviceList'
      })
    },
    showModel(url,item) {
      if (url){
        this.urlVideo = url
        this.videoItem = item
        this.editVisible = true
      }else {
        this.$message.error('暂无卖猪视频')
      }

    },
    init() {
      this.getAppInfo()
      this.getElectricUsed()
      this.getElectricUsedCount()
      this.getWaterUsed()
      this.getWaterUsedCount()
      this.getFoodUsed()
      this.getFeedResidueCount()
      this.getEvnStatusPage()
      this.getPigSaleLog()
      this.getFollowEvent()
      this.getElectricWarnLog()
      this.getCommunicationPage()
      this.getHomePage()
      this.listCollection()
    },
    iconSort(index) {
      return {
        group7: true,
        'flex-col': true,
        iconSort1: index % 4 == 0,
        iconSort2: index % 4 == 1,
        iconSort3: index % 4 == 2,
        iconSort4: index % 4 == 3
      }
    },
    colorOfType(index, type) {
      let obj = {}
      if (index == 1) {
        obj = { layer1: true, 'flex-col': true }
      }
      if (index == 2) {
        obj = { group6: true, 'flex-col': true }
      }
      if (index == 3) {
        obj = { wrap3: true, 'flex-col': true }
      }
      if (index == 4) {
        obj = { mod20: true, 'flex-col': true }
      }
      if (type == 0) obj.color0 = true
      if (type == 1) obj.color1 = true
      if (type == 2) obj.color2 = true
      if (type == 3) obj.color3 = true
      if (type == 4) obj.color4 = true
      if (type == 5) obj.color5 = true
      if (type == 6) obj.color6 = true
      if (type == 7) obj.color7 = true
      if (type == 8) obj.color8 = true
      if (type == 9) obj.color9 = true
      if (type == 10) obj.color10 = true

      return obj

    },
    goto(path, name, target) {
      this.$router.push({ name: target })
      localStorage.setItem('routename', target)
      localStorage.setItem('routetarget', target)
      this.$store.commit('selectMenu', {
        router: path,
        name: name,
        target: target
      })
      this.reload()
    },
    //电列表
    getElectricUsed() {
      this.$http.get(this.$api.getElectricUsed, {
        current: 1,
        size: 10
      }).then(res => {
        if (res.data) {
          this.eleList = res.data.records
          if (this.eleList) {
            this.eleList.map(item => {
              item.buildTypeName = tranBuildType(item.buildType)
            })
          }
        }
      })
    },
    //电合计
    getElectricUsedCount() {
      this.$http.get(this.$api.getElectricUsedCount, {}).then(res => {
        this.eleCount = res.data

      })
    },
    //水列表
    getWaterUsed() {
      this.$http.get(this.$api.getWaterUsed, {
        current: 1,
        size: 10
      }).then(res => {
        if (res.data) {
          this.waterList = res.data.records
          if (this.waterList) {
            this.waterList.map(item => {
              item.buildTypeName = tranBuildType(item.buildType)
            })
          }
        }
      })
    },
    //水合计
    getWaterUsedCount() {
      this.$http.get(this.$api.getWaterUsedCount, {}).then(res => {
        this.waterCount = res.data

      })
    },
    //料塔一览
    getFoodUsed() {
      this.$http.get(this.$api.getFeedUsed, {
        current: 1,
        size: 10
      }).then(res => {
        if (res.data) {
          this.foodList = res.data.records
          if (this.foodList) {
            this.foodList.map(item => {
              item.residue = Math.floor(item.residue * 10) / 10
              item.buildTypeName = tranBuildType(item.buildType)
            })
          }
        }
      })
    },
    //料塔合计
    getFeedResidueCount() {
      this.$http.get(this.$api.getFeedResidueCount, {}).then(res => {
        this.foodCount = Math.floor(res.data / 100) / 10
      })
    },
    //环控
    getEvnStatusPage() {
      this.$http.get(this.$api.getEvnStatusPage, {
        current: 1,
        size: 10
      }).then(res => {
        if (res.data) {
          console.log(res.data.records)
          this.evnData = res.data.records
          if (this.evnData) {
            this.evnData.map(item => {
              let length = 0
              item.buildTypeName = tranBuildType(item.buildType)
              let wd = []
              let sd = []
              let aq = []
              let fj = []
              let sl = []
              if (item.passDataList.length > 0) {
                item.passDataList.map(device => {
                  if (device.passType == '室内温' || device.passType == '室外温') {
                    wd.push(device)
                  } else if (device.passType == '湿度') {
                    sd.push(device)
                  } else if (device.passType == '氨气浓度') {
                    aq.push(device)
                  } else if (device.passType == '定速风机' && device.passValue != '--') {
                    fj.push(device)
                  } else if (device.passType == '水帘' && device.passValue != '--') {
                    sl.push(device)
                  }
                })
                if (wd.length > 0) {
                  item.temperature = wd[0]
                  length++
                }
                if (sd.length > 0) {
                  item.humidity = sd[0]
                  length++
                }
                if (aq.length > 0) {
                  item.ammonia = aq[0]
                  length++
                }
                if (fj.length > 0) {
                  item.fan = fj[0]
                  length++
                }
                if (sl.length > 0) {
                  item.curtain = sl[0]
                  length++
                }

                item.arrLength = length
              }

            })
            console.log(this.evnData)
          }
        }
      })
    },
    //卖猪盘点
    getPigSaleLog() {
      this.$http.get(this.$api.pigSaleLog, {
        deviceType: '卖猪盘点',
        current: 1,
        size: 10
      }).then(res => {
        if (res.data) {
          this.pigSaleData = res.data.records
          if (this.pigSaleData.length > 0){
            this.pigSaleData.map(item=>{
              if (item.updateWeight!=null){
                item.weight = item.updateWeight
              }else {
                item.weight = item.pigWeight
              }
              if (item.updateCount!=null){
                item.count = item.updateCount
              }else {
                item.count = item.pigCount
              }
            })
            this.pigSaleOne = this.pigSaleData[0]
          }

        }
      })
    },
    // 断电告警
    getElectricWarnLog() {
      let that = this
      this.$http.get(this.$api.getFollowEvent, {
        type: '断电报警',
        current: 1,
        size: 4
      }).then(res => {
        if (res.data) {
          this.eleWarnData = res.data.records
          if (this.eleWarnData) {
            this.eleWarnData.map(item => {
              item.buildTypeName = tranBuildType(item.buildType)
            })
          }
        }
      })
    },

    //关注事项
    getFollowEvent() {
      let that = this
      this.$http.get(this.$api.getFollowEvent, {
        current: 1,
        size: 2
      }).then(res => {
        if (res.data) {
          this.followEventData = res.data.records
        }
      })
    },

    //分享沟通
    getCommunicationPage() {
      this.$http.get(this.$api.getCommunicationPage, {
        regionId:store.state.farmId,
        current: 1,
        size: 10
      }).then(res => {
        if (res.result) {
          this.messageData = res.result.records
        }
      })
    },
    //设备信息
    getHomePage() {
      let online = 0
      let offline = 0
      let deviceCount = 0
      this.$http.get(this.$api.getHomePage, {
      }).then(res => {
        if (res.data) {
          res.data.map(item=>{
            online = online + item.online
            offline = offline + item.offline
            deviceCount = deviceCount + item.total
          })
          this.option.data[0].count = deviceCount
          this.option.data[1].count = online
          this.option.data[2].count = offline
        }
      })
    },
    //添加分享沟通
    addCommunication() {
      if (this.messageForm.content == null || this.messageForm.content.trim() == '') {
        this.$message.error('消息不能为空')
        return
      }
      if (this.messageForm.content.length > 150) {
        this.$message.error('消息长度不能超过150字')
        return
      }

      // other.addCommunication()
      this.$http.post(this.$api.addCommunication, {
        ...this.messageForm
      }).then(res => {
        if (res.code == 200) {
          this.$message.success('消息发送成功')
          this.messageForm.content = ''
        } else {
          this.$message.error('消息发送失败')
        }
        this.getCommunicationPage()
      })
    },
    //收藏列表
    listCollection() {
      other.listCollection({sysCode: 'iot'}).then(res => {
        if (res.result) {
            this.collectionList = res.result
        }
      })
    },
    //取消收藏
    deleteCollection(id) {
      let that = this
      other.removeCollection({ menuIds: [id] }).then(res => {
        if (res.code == 200) {
          this.$message.success('取消成功')
          that.listCollection()
        }
      })
    }
  }
}
</script>
<style scoped>

.scroll_hidden::-webkit-scrollbar {
  display: none;
}

>>> .el-textarea__inner {
  padding: 5px 5px !important;
}

>>> .el-card__header {
  padding: 12px;
}

>>> .el-card__body {
  padding: 0px;
}

.card-title {
  min-width: 230px;
  overflow-x: hidden;
}

.card-title2 {
  /*min-width: 350px;*/
  overflow-x: hidden;
}

.card-title3 {
  min-width: 230px;
  overflow-x: hidden;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}


.box-card {
  width: 480px;
}

.fullHeight {
  height: 100%
}


.main21 {
  min-width: 450px;
  margin: 0 16px
  /*overflow-x: auto;*/
}

.main21:hover {
  /*overflow-y: scroll;*/
}

.bd3 {
  z-index: auto;
  /*width: 450px;*/
  height: 20px;
  margin: 16px 1px;
}

.word21 {
  z-index: 692;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  line-height: 17px;
  text-align: left;
  margin-top: 2px;
  margin-left: 2px;
}

.info2 {
  z-index: 695;
  /*width: 80px;*/
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: left;
  margin-left: 10px;
}

.layer1 {
  z-index: 686;
  height: 16px;
  border-radius: 3px;
  width: 36px;
  justify-content: center;
  align-items: center;
  margin: 3px 0 0 5px;
}

.color0 {
  background-color: #375EEF;
}

.color1 {
  background-color: #375EEF;
}

.color2 {
  background-color: #647BD0;
}

.color3 {
  background-color: #349CD2;
}

.color4 {
  background-color: #31BFBB;
}

.color5 {
  background-color: #36BD7F;
}

.color6 {
  background-color: #52BD36;
}

.color7 {
  background-color: #99B52F;
}

.color8 {
  background-color: #E3E3E3;
}

.color9 {
  background-color: #CACACA;
}

.color10 {
  background-color: #AEB6C4;
}

.txt2 {
  z-index: 687;
  width: 36px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  line-height: 8px;
  text-align: left;
}

.font-6px {
  transform: scale(0.7);
  font-size: 12px;
}

.txt6 {
  z-index: 697;
  /*width: 155px;*/
  display: block;
  overflow-wrap: break-word;
  color: rgba(11, 136, 95, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: left;
  margin-left: 33px;
}

.layer2 {
  z-index: 698;
  /*width: 112px;*/
  height: 20px;
  overflow-wrap: break-word;
  text-align: left;
  margin-left: 33px;
  font-size: 0;
}

.txt3 {
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: rgba(210, 94, 46, 0.8);
  line-height: 20px;
}

.txt4 {
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: #BC4D4D;
  line-height: 20px;
}

.word2 {
  font-size: 14px;
  font-family: PingFangSC-Medium;
  color: #BC4D4D;
  line-height: 20px;
}

.word3 {
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: #BC4D4D;
  line-height: 20px;
}

.layer3 {
  z-index: 704;
  width: 15px;
  height: 16px;
  color: #BC4D4D;
  font-size: 18px;
  margin: 2px 0 0 2px;
}

/*====card2=====*/
.main10 {
  z-index: 204;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}

.outer9 {
  z-index: auto;
  padding: 8px 0px;
  margin: 0 16px;
  border-bottom: 1px solid rgba(220, 224, 233, 0.4)

}

.section3 {
  z-index: 217;
  height: 12px;
  width: 12px;
  background-color: rgba(11, 136, 95, 1);
  border-radius: 50%;
  margin-top: 3px;
}

.section3-1 {
  z-index: 217;
  height: 12px;
  width: 12px;
  background-color: #D25E2E;
  border-radius: 50%;
  margin-top: 3px;
}

.section4 {
  z-index: 207;
  height: 20px;
  overflow-wrap: break-word;
  text-align: left;
  margin-left: 12px;
  font-size: 0;
}

.word42 {
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: rgba(42, 42, 55, 1);
  line-height: 20px;
}

.word43 {
  font-size: 14px;
  font-family: PingFangSC-Regular;
  margin-left: 10px;
  color: rgba(11, 136, 95, 1);
  line-height: 20px;
}

.word53 {
  font-size: 14px;
  font-family: PingFangSC-Regular;
  margin-left: 10px;
  color: #D25E2E;
  line-height: 20px;
}

.word53-tip {
  display: none;
}

.section5 {
  z-index: 211;
  height: 17px;
  overflow-wrap: break-word;
  text-align: left;
  font-size: 0;
  margin-left: auto;

  /*margin: 2px 0 0 200px;*/
}

.txt31 {
  font-size: 12px;
  font-family: PingFangSC-Regular;
  color: rgba(42, 42, 55, 1);
  line-height: 17px;
}

.word44 {
  font-size: 12px;
  font-family: PingFangSC-Medium;
  color: rgba(175, 175, 175, 1);
  line-height: 17px;
}

/*  =====card3====*/
.outer3 {
  z-index: auto;
  /*width: 599px;*/
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(220, 224, 233, 0.4);
}

.group4 {
  z-index: auto;
  /*width: 221px;*/
  display: inline-block;
}

.info4 {
  z-index: 752;
  /*width: 184px;*/
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  text-align: left;
  align-self: flex-start;
}

.section1 {
  z-index: 753;
  width: 270px;
  overflow-wrap: break-word;
  text-align: left;
  margin-top: 4px;
  font-size: 0;
}

.info5 {
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
}

.word7 {
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: rgba(53, 138, 255, 1);
  line-height: 20px;
}

.txt14 {
  font-size: 14px;
  font-family: PingFangSC-Regular;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
}

.group5 {
  z-index: 756;
  width: 13px;
  height: 13px;
  font-size: 14px;
  color: #358AFF;
  display: inline-block;
  /*margin: 9px 0 0 305px;*/
}

.group5:hover, .word8:hover {
  cursor: pointer
}

.word8 {
  z-index: 755;
  /*width: 56px;*/
  display: inline-block;
  overflow-wrap: break-word;
  color: rgba(53, 138, 255, 1);
  font-size: 14px;
  font-weight: 400;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: left;
  margin: 7px 0 0 4px;
}

/*======card4=========*/
.iconSort1 {
  background: url(../../assets/img/index/a1.png) 100% no-repeat;
}

.iconSort2 {
  background: url(../../assets/img/index/a2.png) 100% no-repeat;
}

.iconSort3 {
  background: url(../../assets/img/index/a3.png) 100% no-repeat;
}

.iconSort4 {
  background: url(../../assets/img/index/a4.png) 100% no-repeat;
}

.title-water {
  display: inline-block;
  z-index: 525;
  border-radius: 16px;
  background-color: #f5f9ff;
  margin-left: 6px;
  /*width: 170px;*/
  /*height: 20px;*/
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
}

.title-evn {
  display: inline-block;
  z-index: 525;
  border-radius: 16px;
  background-color: rgba(53, 138, 255, 0.05);
  margin-left: 6px;
  height: 20px;
  width: 63%;
  justify-content: center;
  align-items: center;
}

.title-nav {
  color: rgba(42, 42, 55, 0.6);
  display: inline-block;
  width: 100%;
  font-size: 12px;
  /*text-align: center*/
}

.box-water {
  display: inline-block;
  width: 50%;
  padding: 8px 15px 8px 0;
  border-bottom: 1px solid rgba(220, 224, 233, 0.4);
}

.main19 {
  z-index: auto;
  width: 280px;
  height: 20px;
}

.main20 {
  z-index: auto;
  width: 280px;
  height: 25px;
  margin: 4px 0 0 1px;
}

.main14 {
  z-index: 519;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  /*width: 610px;*/
  margin-left: 17px;
  justify-content: center;
}

.bd24 {
  z-index: 613;
  width: 11px;
  font-size: 11px;
  height: 11px;
  margin-top: 6px;
  opacity: 0.4;
}

.word69 {
  z-index: 596;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
  margin-left: 4px;
}

.word70 {
  z-index: 582;
  /*width: 14px;*/
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.6);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 22px;
  text-align: left;
  margin: 3px 0 0 3px;
}

.txt44 {
  z-index: 558;
  /*width: 38px;*/
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.6);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin: 8px 0 0 25px;
}

.word71 {
  z-index: 560;
  display: block;
  overflow-wrap: break-word;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2A2A37;
  line-height: 28px;
  text-align: left;
  margin-left: 4px;
}

.word72 {
  display: block;
  overflow-wrap: break-word;
  font-size: 10px;
  color: rgba(42, 42, 55, 0.6);
  line-height: 14px;
  text-align: left;
  margin: 9px 0 0 3px;
}

.word73 {
  z-index: 599;
  width: 45px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
  margin-left: 4px;
}

.txt45 {
  z-index: 591;
  width: 14px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  line-height: 22px;
  text-align: left;
  margin: 3px 0 0 3px;
}

.word74 {
  z-index: 590;
  width: 5px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 8px;
  font-family: PingFangSC-Regular;
  line-height: 11px;
  text-align: left;
  margin-top: 9px;
}

.word75 {
  z-index: 570;
  width: 38px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.6);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin: 8px 0 0 32px;
}

.word76 {
  z-index: 572;
  width: 21px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
  margin-left: 4px;
}

.word77 {
  z-index: 571;
  width: 10px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.6);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin: 8px 0 0 3px;
}

.txt43 {
  z-index: 533;
  /*display: block;*/
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2A2A37;
}

.group6 {
  z-index: 542;
  height: 16px;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  margin: 3px 0 0 6px;
}

.word67 {
  z-index: 543;
  display: block;
  width: 36px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  transform: scale(0.7);
  line-height: 8px;
  text-align: left;
}

.group7 {
  z-index: 982;
  width: 45px;
  margin-left: 6px;
  /*margin: 0 0 0 6px;*/
}

/*=====card5====*/
.layer8 {
  z-index: 999;
  margin: 5px 17px 0 17px;
  /*width: 603px;*/
  height: 50px;
  border-bottom: 1px solid rgba(220, 224, 233, 0.4);;
}

.layer8:hover {
  cursor: pointer
}

.word17 {
  z-index: 282;
  /*width: 81px;*/
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: left;
  margin-top: 12px;
  padding-right: 4px;
}

.wrap3 {
  z-index: 286;
  height: 16px;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
}

.word18 {
  z-index: 290;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  transform: scale(0.7);
  /*line-height: 8px;*/
  text-align: left;
}

.wrap4 {
  z-index: 254;
  height: 22px;
  overflow-wrap: break-word;
  text-align: left;
  font-size: 0;
}

.info8 {
  font-size: 16px;
  font-family: PingFangSC-Regular;
  color: rgba(0, 0, 0, 0.8);
  line-height: 22px;
}

.word19 {
  font-size: 12px;
  font-family: PingFangSC-Regular;
  color: rgba(177, 177, 177, 0.8);
  line-height: 22px;
}

.word-c19 {
  display: block;
  font-size: 12px;
  /*width: 40px;*/
  font-family: PingFangSC-Regular;
  color: rgba(177, 177, 177, 0.8);
  line-height: 22px;
}

.wrap5 {
  z-index: 262;
  /*width: 52px;*/
  height: 26px;
  overflow-wrap: break-word;
  text-align: left;
  margin-left: 25px;
  /*margin-left: 3%;*/
  font-size: 0;
}

.word20 {
  font-size: 16px;
  font-family: PingFangSC-Regular;
  color: rgba(42, 42, 55, 1);
  line-height: 22px;
}

.word211 {
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 22px;
  /*width: 19%;*/
  /*overflow: hidden;*/
  /*white-space: nowrap;*/
  /*text-overflow: ellipsis;*/
}

.word-c21 {
  display: block;
  font-size: 12px;
  font-family: PingFangSC-Regular;
  color: rgba(175, 175, 175, 1);
  line-height: 26px;
}

.wrap6 {
  z-index: 270;
  width: 65px;
  height: 22px;
  overflow-wrap: break-word;
  text-align: left;
  margin-left: 25px;
  font-size: 0;
}

.word22 {
  font-size: 16px;
  font-family: PingFangSC-Regular;
  color: rgba(42, 42, 55, 1);
  line-height: 22px;
}

.txt18 {
  font-size: 12px;
  margin-left: 1px;
  font-family: PingFangSC-Regular;
  color: rgba(183, 183, 183, 1);
  line-height: 22px;
}

.wrap7 {
  z-index: auto;
  min-width: 55px;
  height: 34px;
  margin-left: 20px;
  margin-top: 1px;
}

.icon2 {
  z-index: 302;
  width: 19px;
  height: 19px;
  align-self: center;
}

.icon2-on {
  z-index: 302;
  width: 19px;
  height: 19px;
  color: #2F99F3;
  align-self: center;
}

.info9 {
  z-index: 274;
  /*width: 25px;*/
  display: block;
  overflow-wrap: break-word;
  color: rgba(183, 183, 183, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: center;
  /*align-self: flex-start;*/
  margin-top: 9px;
}

.wrap8 {
  z-index: auto;
  width: 40px;
  height: 33px;
  margin: 1px 0 0 25px;
}

.label6 {
  z-index: 366;
  width: 16px;
  height: 16px;
  margin-left: 3px;
  margin-top: 1px;
  align-self: center;
}

.label6-on {
  z-index: 366;
  width: 16px;
  height: 16px;
  margin-left: 3px;
  margin-top: 1px;
  color: #2F99F3;
  align-self: center;
}

.txt19 {
  z-index: 278;
  display: block;
  overflow-wrap: break-word;
  color: rgba(183, 183, 183, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: center;
  margin-top: 9px;
}

.label7 {
  z-index: 227;
  width: 16px;
  height: 16px;
  margin-top: 11px;
  margin-left: auto;
  color: #2F99F3;
  /*margin: 7px 0 0 60px;*/
}

.label7:hover {
  cursor: pointer
}

/*  ========料塔===== */
.main43 {
  display: inline-block;
  width: 50%;
}

.main44 {
  z-index: auto;
  width: 250px;
  height: 20px;
  margin-top: 12px;
}

.txt63 {
  z-index: 498;
  /*width: 54px;*/
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: left;
}

.mod20 {
  z-index: 790;
  height: 16px;
  border-radius: 3px;
  /*width: 30px;*/
  justify-content: center;
  align-items: center;
  margin: 3px 0 0 5px;
  padding: 1px;
}

.word130 {
  z-index: 791;
  /*width: 18px;*/
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  transform: scale(0.7);
  line-height: 8px;
  text-align: left;
}

.word131 {
  z-index: 501;
  width: 54px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: left;
  margin-left: 228px;
}

.mod21 {
  z-index: 799;
  height: 14px;
  border-radius: 3px;
  background-color: rgba(225, 157, 95, 1);
  width: 30px;
  justify-content: center;
  align-items: center;
  margin: 3px 0 0 5px;
}

.word132 {
  z-index: 800;
  width: 18px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 6px;
  font-family: PingFangSC-Regular;
  line-height: 8px;
  text-align: left;
}

.main45 {
  z-index: auto;
  width: 250px;
  height: 41px;
  margin-top: 8px;
  justify-content: space-between;
}

.mod22 {
  z-index: 648;
  height: 41px;
  border-radius: 4px;
  background-color: rgba(244, 244, 245, 1);
  width: 220px;
  justify-content: center;
  align-items: center;
}

.block9 {
  z-index: auto;
  /*width: 180px;*/
  height: 25px;
  justify-content: space-between;
}

.word133 {
  z-index: 843;
  width: 10px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.6);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin-top: 7px;
}

.info53 {
  z-index: 888;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
  padding: 0 5px;
}

.info54 {
  z-index: 837;
  width: 20px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.6);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin-top: 7px;
}

.txt64 {
  z-index: 951;
  width: 10px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin-top: 7px;
}

.info55 {
  z-index: 825;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.6);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin-top: 7px;
}

.word134 {
  z-index: 849;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
  padding: 0 5px;
}

.word135 {
  z-index: 831;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.6);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin-top: 7px;
}

.mod23 {
  z-index: 651;
  height: 41px;
  border-radius: 4px;
  background-color: rgba(244, 244, 245, 1);
  width: 187px;
  justify-content: center;
  align-items: center;
}

.bd21 {
  z-index: auto;
  width: 163px;
  height: 25px;
  justify-content: space-between;
}

.txt65 {
  z-index: 846;
  width: 10px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.6);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin-top: 7px;
}

.word136 {
  z-index: 891;
  width: 27px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
}

.txt66 {
  z-index: 840;
  width: 10px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.6);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin-top: 7px;
}

.word137 {
  z-index: 954;
  width: 10px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin-top: 7px;
}

.word138 {
  z-index: 828;
  width: 40px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.6);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin-top: 7px;
}

.info56 {
  z-index: 852;
  width: 32px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
}

.word139 {
  z-index: 834;
  width: 12px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.6);
  font-size: 10px;
  font-family: PingFangSC-Regular;
  line-height: 14px;
  text-align: left;
  margin-top: 7px;
}

/*====分享沟通======*/
.main25 {
  z-index: 3;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  /*box-shadow: 0px 2px 8px 0px rgba(221, 221, 221, 1);*/
  align-self: flex-start;
  justify-content: flex-start;
  /*padding-top: 10px;*/
}

.outer15 {
  z-index: auto;
  height: 25px;
  width: 318px;
  margin-left: 10px;
}

.outer152 {
  z-index: auto;
  height: 25px;
  width: 275px;
  padding-top: 10px;
  margin-left: 10px;
}

.box30 {
  z-index: 27;
  width: 21px;
  height: 21px;
  line-height: 21px;
  margin-top: 3px;
}

.info48 {
  z-index: 4;
  width: 72px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  line-height: 25px;
  text-align: left;
  margin-left: 2px;
}

.word120 {
  z-index: 7;
  width: 64px;
  display: block;
  overflow-wrap: break-word;
  color: #409EFF;
  font-size: 14px;
  text-align: left;
  margin: -7px 0 0 152px;
}

.outer16 {
  /*margin-top: 16px;*/
}

.outer17 {
  z-index: 11;
  height: 240px;
  /*width: 318px;*/
  overflow-y: scroll;
  scrollbar-width: none;
  /*background-color: rgba(242, 242, 242, 1);*/
}

.outer17::-webkit-scrollbar {
  display: none;
}

.main26 {
  z-index: auto;
  margin-left: 16px;
}

.word121 {
  z-index: 12;
  width: 52px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.8);
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
}

.info49 {
  margin-top: 6px;
  z-index: 16;
  display: block;
  overflow-wrap: break-word;
  color: rgba(42, 42, 55, 0.3);
  font-size: 12px;
  line-height: 17px;
  text-align: center;
}


.main27 {
  margin: 4px 16px 4px 16px;
  padding: 4px;
  background-color: #f8f8f8;
  /*border-bottom: 1px solid rgba(211, 215, 223, 0.4);*/
}

.txt58 {
  z-index: 20;
  display: block;
  width: 245px;
  overflow-wrap: break-word;
  color: rgba(60, 66, 73, 0.6);
  font-size: 14px;
  line-height: 17px;
  text-align: left;
}

>>> .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1;
  box-sizing: border-box;
  width: 100%;
  font-size: inherit;
  color: #606266;
  background-color: #FFF;
  background-image: none;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
}

/*===收藏===*/
.collection-card {
  overflow-y: scroll;
}

/*定义滚动条样式（高宽及背景）*/
.collection-card::-webkit-scrollbar, .outer17::-webkit-scrollbar {
  width: 6px; /* 滚动条宽度， width：对应竖滚动条的宽度  height：对应横滚动条的高度*/
}

/*定义滚动条轨道（凹槽）样式*/
.collection-card::-webkit-scrollbar-track, .outer17::-webkit-scrollbar {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); /* 较少使用 */
  border-radius: 3px;
}

/*定义滑块 样式*/
.collection-card::-webkit-scrollbar-thumb, .outer17::-webkit-scrollbar {
  border-radius: 3px;
  height: 100px; /* 滚动条滑块长度 */
  background-color: #ccc;
}

.card {
  display: inline-block;
  padding: 4px;
  width: 50%;
}

>>> .card .el-card__body {
  padding: 0;
  font-size: 14px;
  /*width: 146px;*/
  height: 65px;
  background-color: #F2F2F2;
}

>>> .card .el-card.is-hover-shadow:hover {
  border-radius: 4px;
  box-sizing: border-box;
  transition: box-shadow .5s;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  text-decoration: none;
}

>>> .card .el-card {
  /*width: 146px;*/
}

>>> .card .el-card__header {
  padding: 2px 3px;
  background-color: #ECECEC;
  border-bottom: 1px solid #EBEEF5;
  box-sizing: border-box;
}

.delete:hover {
  cursor: pointer
}

.card-main {
  /*width: 135px;*/
  height: 65px;
  text-align: center;
  line-height: 65px;
}

.card-main:hover {
  cursor: pointer;
}

.card:hover span:nth-child(2) {
  opacity: 1 !important;
  transition: 1.5s;
  -webkit-transition: 1.5s;
}

/*======低分辨率=====*/
@media screen and (max-width: 1600px) {
  .word211 {
    font-size: 14px;
    font-family: PingFangSC-Regular;
    line-height: 22px;
    width: 19%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .main21 {
    min-width: auto;
    margin-left: 17px;
  }

  .info2 {
    z-index: 695;
    width: 20%;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    font-family: PingFangSC-Regular;
    line-height: 20px;
    text-align: left;
    margin-left: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .layer2 {
    z-index: 698;
    width: 30%;
    height: 20px;
    overflow-wrap: break-word;
    text-align: left;
    margin-left: 33px;
  }

  .txt3 {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .section3 {
    z-index: 217;
    height: 10px;
    width: 10px;
    background-color: rgba(11, 136, 95, 1);
    border-radius: 50%;
    margin-top: 5px;
  }

  .section3-1 {
    z-index: 217;
    height: 10px;
    width: 10px;
    background-color: #D25E2E;
    border-radius: 50%;
    margin-top: 5px;
  }

  .section4 {
    width: 60%;
  }

  .word42 {
    display: inline-block;
    /*width: 35%;*/
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .word43 {
    font-size: 14px;
    color: rgba(11, 136, 95, 1);
    line-height: 20px;
    display: inline-block;
    margin-left: 10px;
    width: 40%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .word53 {
    font-size: 14px;
    color: #D25E2E;
    line-height: 20px;
    margin-left: 10px;
    display: inline-block;
    width: 30%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .word53-tip {
    display: inline-block;
  }

  .word-none {
    display: none;
  }

  .title-evn {
    display: none;
  }

  .title-water {
    width: 40%;
    display: inline-block;
    z-index: 525;
    border-radius: 16px;
    background-color: #f5f9ff;
    margin-left: 6px;
    /*height: 20px;*/
    padding: 4px 0 4px 8px;
    justify-content: center;
    align-items: center;
  }

  .box-water {
    display: inline-block;
    width: 225px;
    padding: 8px 15px 8px 0;
    border-bottom: 1px solid rgba(220, 224, 233, 0.4);
  }

  .txt43 {
    z-index: 533;
    /*display: block;*/
    overflow-wrap: break-word;
    color: rgba(42, 42, 55, 1);
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    font-weight: 400;
    color: #2A2A37;
    width: 60px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .txt44 {
    margin: 8px 0 0 10px;
  }
}


.words {
  width: 36px;
  height: 17px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(60, 66, 73, 0.8);
  line-height: 17px;
}

.pigging {
  padding: 1px;
  background-color: rgba(225, 157, 95, 0.2);
  font-size: 10px;
  border-radius: 3px;
  color: #E19D5F;
  font-weight: 400;
  margin-right: 4px;
}

.evn >>> .el-card__body {
  max-height: 220px;
  overflow-y: scroll;
  scrollbar-width: none;

}

.evn >>> .el-card__body::-webkit-scrollbar {
  display: none;
}

.pigList::-webkit-scrollbar {
  display: none;
}

.event_title {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #2A2A37;
  line-height: 20px;
}

.event_desc {
  height: 16px;
  line-height: 15px;
  background: rgba(225, 157, 95, 0.1);
  border-radius: 2px;
  border: 1px solid #E89142;
  font-size: 10px;
  font-weight: 500;
  color: #E89142;
}

.event_info {
  height: 25px;
  line-height: 25px;
  background: #EFEFEF;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 400;
  color: #2A2A37;
}

.event_status {
  height: 25px;
  line-height: 25px;
  background: rgba(11, 136, 95, 0.1);
  border-radius: 2px;
  font-size: 12px;
  font-weight: 400;
  color: #419076;
}

.event_status_ing {
  height: 25px;
  line-height: 25px;
  background: rgba(188, 77, 77, 0.1);
  border-radius: 2px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(188, 77, 77, 1)
}

.event_box {
  margin-left: 6px;
  padding: 0 5px
}

.device_name {
  height: 17px;
  font-size: 12px;
  font-weight: 400;
  color: #737F90;
  line-height: 17px;
}
/*================pig============*/
.pig_video{
  border-radius: 5px;
  min-height: 160px;
  background-color: #2A2A37;
  border: 1px solid #f5f5f5
}
.pig_video:hover , .pig_vp:hover , .pig_desc:hover{
  cursor: pointer;
}
.pig_vp{
  border-radius: 5px;
  margin-top:5px;
  background-color: #2A2A37;
  border: 1px solid #f5f5f5
}
.pig_desc{
  margin-left: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height:20px;
  color: #2A2A37;
  margin-top: 3px
}
/*===================tabs=====================*/
>>> .el-tabs__item {
  height: 40px;
  line-height: 30px;
}

>>> .el-tabs__header {
  margin: 0;
}

.tabs_box >>> .el-card__header {
  padding-bottom: 0;
}

.tabs_box >>> .el-card__body {
  padding: 0 14px;
}

>>> .el-tabs__nav-wrap::after {
  height: 0;
}

>>> .el-tabs__nav-wrap {
  margin-bottom: -3px;
}

.tabs_title {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #2A2A37;
  line-height: 20px;
  margin-top: 10px;
}

.tabs_device {
  height: 17px;
  font-size: 12px;
  font-weight: 400;
  color: #737F90;
  line-height: 17px;
  margin-top: 4px;
}

>>> .el-input--small .el-input__inner{
  background-color: #EFEFEF;
}

>>> .data-box .item-icon{
  display: none;
}
>>> .data-box .item-info {
  border-radius: 0 5px 5px 0;
  border: none;
  background-color: #fff;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
>>> .data-box .item a {
  display: flex;
  height: 100px;
}
</style>
