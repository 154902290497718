/**
 * 搜索栏公共组件
 */
<template>
  <div class="search-form-box">
    <el-form

      :model="formData"
      ref="formRef"
      :inline="true"
    >
      <el-form-item
        v-for="(item, index) in formOptions"
        :key="newKeys[index]"
        :prop="item.prop"
        :rules="item.rules"
      >
        <span slot="label">
          <el-tooltip v-if="item.showTooltip" class="item" effect="dark"  placement="top" content="原栋舍" ><i class="el-icon-info"></i></el-tooltip>
          {{item.label ? (item.label + '：') : ''}}
        </span>
        
        <formItem
          v-model="formData[item.prop]"
          :itemOptions="item"
          :isChange="item.isChange"
          @getSelectId = "getSelectId"
          @getSelectId2 = "getSelectId2"
        />
      </el-form-item>
    </el-form>

    <!-- 提交按钮 -->
    <div class="btn-box">
      <el-button
        v-if="btnItems.includes('search')"
        size="mini"
        type="primary"
        class="btn-search"
        @click="onSearch"
      >搜索</el-button>

      <el-button
        v-if="btnItems.includes('reset')"
        size="mini"
        type="default"
        class="btn-reset"
        @click="onReset"
      >重置</el-button>

      <el-button
        v-if="btnItems.includes('export')"
        size="mini"
        type="primary"
        class="btn-export"
        @click="onExport"
      >导出</el-button>

    </div>
  </div>
</template>

<script>
import formItem from './formItem'
import {createUniqueString} from '@/libs/tools'

export default {
  inject: ['reload'],
  props: {
    /**
     * 表单配置
     * 示例：
     * [{
     *   label: '用户名', // label文字
     *   prop: 'username', // 字段名
     *   element: 'el-input', // 指定elementui组件
     *   initValue: '阿黄', // 字段初始值
     *   placeholder: '请输入用户名', // elementui组件属性
     *   rules: [{ required: true, message: '必填项', trigger: 'blur' }], // elementui组件属性
     *   events: { // elementui组件方法
     *     input (val) {
     *       console.log(val)
     *     },
     *     ...... // 可添加任意elementui组件支持的方法
     *   }
     *   ...... // 可添加任意elementui组件支持的属性
     * }]
     */
    formOptions: {
      type: Array,
      required: true,
      default () {
        return []
      }
    },
    // 提交按钮项，多个用逗号分隔（search, export, reset）
    btnItems: {
      type: String,
      default () {
        return 'search'
      }
    }
  },

  data () {
    return {
      formData: {},
      loading:false
    }
  },

  computed: {
    newKeys () {
      return this.formOptions.map(v => {
        return createUniqueString()
      })
    },
    buildType(){
      return this.formData.buildType
    }
  },
  watch: {
    buildType(){
      this.$set(this.formData, 'buildName', undefined)
    }
  },

  created () {
    this.addInitValue()
  },

  methods: {
    // 校验
    onValidate (callback) {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          callback()
        }
      })
    },
    getSelectId(val,prop){
      this.$emit('setSelect',val,prop)
      this.$set(this.formData, 'fieldId', undefined)
    },
    getSelectId2(val){
      this.$emit('setSelect2',val)
    },
    // 搜索
    onSearch () {
      this.onValidate(() => {
        this.$emit('onSearch', this.formData)
      })
    },
    // 导出
    onExport () {
      // this.loading = true
      this.onValidate(() => {
        this.$emit('onExport', this.formData)
      })
    },
    onReset () {
      this.onValidate(() => {
        this.$refs.formRef.resetFields();
        this.reload()
        this.$emit('onSearch')
      })
    },
    // 添加初始值
    addInitValue () {
      const obj = {}
      this.formOptions.forEach(v => {
        if (v.initValue !== undefined) {
          obj[v.prop] = v.initValue
        }
      })
      this.formData = obj
      // console.log( this.formData)
    }
  },

  components: { formItem }
}
</script>

<style lang='scss' scoped>
.search-form-box {
  display: flex;
  margin-bottom: 15px;

  .btn-box {
    padding-top: 5px;
    display: flex;

    button {
      height: 28px;
    }
  }
  .el-form {
    >>> .el-form-item__label {
      padding-right: 0;
    }
    .el-form-item {
      margin-bottom: 0;
      //width:240px;
      margin:0 15px 0 0;
      &.is-error {
        margin-bottom: 22px;
      }
    }
    // el-input宽度
    ::v-deep .form-item {
      > .el-input:not(.el-date-editor) {
        width: 135px;
      }
    }
    ::v-deep .el-select {
      width: 140px;
    }
    //::v-deep .el-cascader {
    //  width: 150px;
    //}
  }
}

</style>
