<template>
  <div style="background-color: white;padding: 10px;border-radius: 8px;height: calc(100vh - 132px);overflow-y: hidden">
    <searchForm :formOptions="formOptions" @onSearch="onSearch" :btnItems="btnItems" @onExport="onExport" @setSelect="setSelect" />
    <div class="title">
      共 <span style="color:black">{{ totalDevice }}</span> 条, 在线 <span style="color:black">{{ onlineDevice }}</span
      >, 离线 <span style="color:red">{{ offlineDevice }}</span>
    </div>
    <page-table
      v-if="hardReset"
      v-loading="loading"
      :tableData="tableData"
      :tableLabel="tableLabel"
      :tableOption="tableOption"
      class="gdtable"
      :toTop="toTop"
      :isStatistics="isStatistics"
      :pager="pager"
      @pagination="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      @handleButton="tableBtnHandle(arguments)"
      @changeTop="changeTop"
      @cancelTop="cancelTop"
      @changeStatistics="changeStatistics"
      @cancelStatistics="cancelStatistics"
    ></page-table>
  </div>
</template>

<script>
import pageTable from '@/components/pageTable'
import searchForm from '@/components/searchForm'
import login from '../../api/user/login'
import store from '../../store'
import { numberTran, tranBuildType } from '../../libs/tools'
import axios from 'axios'
import { getDownload } from '../../libs/axios'

export default {
  name: 'foodDeviceList',
  components: {
    pageTable,
    searchForm
  },
  data() {
    return {
      selectBuildId: '',
      hardReset: true,
      loading: true,
      totalDevice: 0,
      onlineDevice: 0,
      offlineDevice: 0,
      obj: undefined,
      btnItems: 'search' + 'export' + 'reset',
      buildType: '',
      formOptions: [
        {
          label: '栋舍',
          prop: 'buildingId',
          element: 'el-select',
          options: this.getBuildType(),
          isChange: 'change'
        },
        {
          label: '单元/区',
          prop: 'buildId',
          element: 'el-select',
          showTooltip: true,
          options: []
        },
        {
          label: '状态',
          prop: 'online',
          element: 'el-select',
          options: [
            { label: '在线', value: '1' },
            { label: '离线', value: '0' }
          ]
        },
        {
          label: '设备名称',
          prop: 'deviceName',
          element: 'el-input',
          placeholder: '请输入设备名称'
        }
      ],
      tableData: [],
      tableLabel: [
        { label: '栋舍名称', param: 'buildingName' },
        { label: '单元/区名称', param: 'buildName' },
        { label: '单元类型', param: 'buildType' },
        { label: '设备名称', param: 'deviceName' },
        { label: '余料(kg)', param: 'residue', align: 'right' },
        { label: '今日来料(kg)', param: 'coming', align: 'right' },
        { label: '7日均耗料(kg)', param: 'avgAmount', align: 'right' },
        { label: '状态', param: 'online', status: 1 }
      ],
      tableOption: {
        label: '操作',
        options: [{ label: '详情', methods: 'editDetail' }]
      },
      toTop: {
        label: '置顶',
        options: [{ label: '详情', methods: 'editDetail' }]
      },
      isStatistics: {
        label: '不统计',
        options: [{ label: '详情', methods: 'editDetail' }]
      },
      pager: {
        pageSize: 10, // 每页数据量
        currentPage: 1, // 分页  当前页
        total: 0 //列表总数
      }
    }
  },
  watch: {
    buildType(val) {
      this.getAllPigpen(val)
    }
  },
  mounted() {
    console.log('我刷新了')

    this.getFoodDeviceList()
    this.getStatusCount()
    this.getAllPigpen()
  },
  methods: {
    setSelect(val) {
      this.selectBuildId = val
      this.buildType = val
    },
    //料塔设备列表
    getFoodDeviceList(obj) {
      let that = this
      this.$http
        .get(this.$api.feedDeviceList, {
          ...obj,
          deviceType: '料塔',
          current: that.pager.currentPage,
          size: that.pager.pageSize
        })
        .then(res => {
          let arr = res.data.records
          if (arr) {
            arr.map(item => {
              if (item.online == 1) {
                item.online = '在线'
              } else {
                item.online = '离线'
              }
              item.residue = numberTran(item.residue)
              item.coming = numberTran(item.coming)
              item.avgAmount = numberTran(item.avgAmount)
              item.buildType = tranBuildType(item.buildType)
            })
          }
          this.tableData = arr
          this.pager.total = res.data.total
          this.loading = false
          this.hardReset = true
        })
        .catch(e => {
          this.loading = false
          this.hardReset = true
        })
    },
    //料塔统计
    getStatusCount(obj) {
      let that = this
      this.$http
        .get(this.$api.getStatusCount, {
          ...obj,
          deviceType: '料塔'
        })
        .then(res => {
          if (res.data) {
            that.offlineDevice = res.data.offline
            that.onlineDevice = res.data.online
            that.totalDevice = res.data.total
          }
        })
    },
    //单元类型列表
    getBuildType() {
      let arr = []
      this.$http
        .get(this.$api.getBuildList, {
          deviceType: '料塔'
        })
        .then(res => {
          console.log()
          if (res.data) {
            res.data.map(item => {
              arr.push({
                label: item.name,
                value: item.id
              })
            })
          }
        })
      return arr
    },
    //栋舍列表
    getAllPigpen(val) {
      let arr = []
      this.formOptions[1].options = []
      this.$http
        .get(this.$api.getAllPigpen, {
          deviceType: '料塔',
          // buildType:val,
          buildingId: this.selectBuildId
        })
        .then(res => {
          if (res.data) {
            res.data.map(item => {
              arr.push({
                label: item.name,
                value: item.id
              })
            })
            this.formOptions[1].options = arr
          }
        })
      return arr
    },
    //翻页
    handleCurrentChange(val) {
      this.pager.currentPage = val
      this.loadTable(this.obj)
    },
    //页码大小
    handleSizeChange(val) {
      this.pager.pageSize = val
      this.loadTable(this.obj)
    },
    changeTop(val) {
      this.$http.patch(this.$api.changeTop(val), {}).then(res => {})
    },
    cancelTop(val) {
      this.$http.patch(this.$api.cancelTop(val), {}).then(res => {})
    },
    changeStatistics(val,id) {
      const param = {
        id,
        notCount: 1
      }
      this.$http.post(this.$api.setNotCount, param).then(res => {})
    },
    cancelStatistics(val,id) {
      const param = {
        id,
        notCount: 0
      }
      this.$http.post(this.$api.setNotCount, param).then(res => {})
    },
    tableBtnHandle(arg) {
      console.log(arg[1])
      let passagewayId = arg[1].passagewayId
      this.$router.push({
        path: 'seo',
        query: {
          passagewayId: passagewayId,
          buildType: arg[1].buildType,
          buildName: arg[1].buildName,
          deviceName: arg[1].deviceName,
          type: arg[1].deviceType
        }
      })
      localStorage.setItem('routename', 'seo')
      localStorage.setItem('routetarget', 'seo')
      this.$store.commit('selectMenu', {
        router: 'setting/seo',
        name: '综合查询',
        target: 'seo'
      })
    },
    onSearch(val) {
      this.$set(this.pager, 'currentPage', 1)
      this.hardReset = false
      this.obj = val
      this.loadTable(val)
    },
    //重载表格
    loadTable(val) {
      this.hardReset = false
      this.loading = true
      this.getFoodDeviceList(val)
      this.getStatusCount(val)
    },
    onExport(val) {
      this.obj = val
      this.loadTable(val)
      this.exportWaterList(val)
    },
    //列表导出
    exportWaterList(obj) {
      // let urls = store.state.baseUrl+this.$api.exportFeedList +'?deviceType=料塔&current=-1&size=-1&farmId=' + store.state.farmId
      // let buildType = ""
      // let buildName = ""
      // let online = ""
      // let deviceName = ""
      // if (obj.buildType){
      //   buildType = obj.buildType
      //   urls = urls + '&buildType=' + buildType
      // }
      // if (obj.buildName){
      //   buildName = obj.buildName
      //   urls = urls + '&buildName=' + buildName
      // }
      // if (obj.online){
      //   online = obj.online
      //   urls = urls + '&online=' + online
      // }
      // if (obj.deviceName){
      //   deviceName = obj.deviceName
      //   urls = urls + '&deviceName=' + deviceName
      // }
      // window.location.href = urls
      axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('token')
      let headers = {
        'x-auth-token': localStorage.getItem('token')
      }
      getDownload(
        this.$api.exportFeedList,
        {
          farmId: store.state.farmId,
          ...obj
        },
        headers
      ).then(res => {
        let blob = new Blob([res.data])
        let downloadElement = document.createElement('a')
        let href = window.URL.createObjectURL(blob) //创建下载的链接
        downloadElement.href = href
        // downloadElement.download = decodeURIComponent(res.headers.filename); //下载后文件名
        downloadElement.download = `料塔设备列表.xlsx` //下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() //点击下载
        document.body.removeChild(downloadElement) //下载完成移除元素
        window.URL.revokeObjectURL(href) //释放blob对象
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .gdtable .el-table__body-wrapper {
  height: calc(100vh - 327px) !important;
  overflow-y: auto !important;
}
.title {
  margin-bottom: 10px;
  color: #606266;
}
</style>
