import Vue from 'vue'
// import './plugins/axios.js'
import App from './App.vue'
// import VueRouter from 'vue-router';
import routes from './router'
import store from './store'
import './plugins/element.js'
// import api from './api/index'
// import './mock/index.js'
import api from '@/api/apiList'
import api2 from '@/api/apiList2'
import './public-path';
import "./assets/style/global.scss";
import Request from "./api/request"
import axios from 'axios'

import ECharts from 'vue-echarts'
//手动引入，减小打包体积
import 'echarts/lib/component/polar'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/scatter'
import 'echarts/lib/chart/pie'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/gauge'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/markLine'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title.js'
import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';
Vue.use(Avue);
// 注册组件后即可使用
Vue.component('v-chart', ECharts)

axios.defaults.withCredentials = true
axios.defaults.crossDomain = true

Vue.config.productionTip = false


// 将导入的接口文件全局挂载在vue全局上
Vue.prototype.$http = Request;
let allApi =Object.assign(api,api2);
Vue.prototype.$api = allApi;

let router = routes;
let instance = null;
function render(props = {}) {

  const { container,appContent } = props;

  instance = new Vue({
    store,
    router,
    //render: (h) => h(App),
    render(h){
      return h(App,{
        props:{
          content:appContent
        }
      })
    }
  }).$mount(container ? container.querySelector('#app') : '#app');
}

// 独立运行时
if (!window.__POWERED_BY_QIANKUN__) {
  render();
}

export async function bootstrap(props) {
  // console.log(props);
}
export async function mount(props) {
  render(props);
}
export async function unmount() {
  instance.$destroy();
  instance.$el.innerHTML = '';
  instance = null;
}
