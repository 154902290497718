export default{
  getMenuList(id){
    //获取当前登陆用户应用菜单列表
    return  `/iot/plat/menu/getMenuByUser/${id}`
  },
  changeTop(deviceId){
    //置顶
    return  `/iot/DeviceTop/top/${deviceId}`
  },
  cancelTop(deviceId){
    //取消置顶
    return  `/iot/DeviceTop/unTop/${deviceId}`
  },
  setNotCount:`/iot/Device/setNotCount`,//消耗量不列入统计

  getUserInfo:`/upms/User/getUserInfo`,//用户信息

  getAppInfo:'/iot/HomePage/getAppInfo',

  createSalt:'/iot/plat/token/salt',//生成随机盐
  // login:'/plat/token/generate',
  login:'/iot/admin/adminPost',
  getCurrPigFarms:'/iot/Pigfarm/getCurrPigFarms',//获取组织
  waterDeviceList:'/iot/Device/waterPage',
  waterLog:'/iot/Device/waterHistory',
  electricDeviceList:'/iot/Device/electricPage',
  electricLog:'/iot/Device/electricHistory',
  electricWarnLog:'/iot/Followevent/eleWarnHistoryPage',
  feedDeviceList:'/iot/Device/feedPage',
  feedLog:'/iot/Device/feedHistory',
  evnPage:'/iot/Device/evnPage',
  getFollowEvent:'/iot/Followevent/page',

  electricWarnList:'/iot/Device/electricWarnPage',
  videoInventoryList:'/iot/Device/videoInventoryPage',
  videoInventoryLog:'/iot/Device/videoInventoryHistory',

  pigSaleList:'/iot/Device/pigSalePage',
  pigSaleLog:'/iot/Device/pigSaleHistory',

  statusCount:'/iot/Passageway/statusCount', //环控设备在离线统计
  getStatusCount:'/iot/Device/statusCount',
  getLogCount:'/iot/Device/waAndElHistoryDataSum',
  getFeedLogCount:'/iot/Device/feedHistoryDataSum',
  getVideoLogCount:'/iot/Device/videoInventoryHistoryDataSum',
  getPigSaleLogCount:'/iot/Device/pigSaleHistoryDataSum',

  //栋舍
  getAllPigpen:'/iot/Pigpen/getAllPigpen',
  getBuildType:'/iot/Pigpen/getBuildType',

  //首页
  getElectricUsed:'/iot/HomePage/electricUsed',
  getElectricUsedCount:'/iot/HomePage/electricUsedCount',
  getWaterUsed:'/iot/HomePage/waterUsed',
  getWaterUsedCount:'/iot/HomePage/waterUsedCount',
  getFeedUsed:'/iot/HomePage/feedUsed',
  getFeedResidueCount:'/iot/HomePage/feedResidueCount',
  getEvnStatusPage:'/iot/HomePage/evnStatusPage',

  getCommunicationPage:'/system/Communication/page',
  addCommunication:'/system/Communication/publish',

  resetCommunication(id){
    return `/system/Communication/${id}`
  },

  // addCollection:'/upms/MenuCollection/add',

  //综合查询
  getAllDeviceList:'/iot/MultipleQuery/page',
  dataQuery:'/iot/MultipleQuery/dataQuery',
  getFeedTimeData:'/iot/feedTimeData/getData',

  exportWaterList:'/iot/Export/exportWaterList',
  exportHisWaterList:'/iot/Export/exportHisWaterList',
  exportEleList:'/iot/Export/exportEleList',
  exportHisEleList:'/iot/Export/exportHisEleList',
  exportFeedList:'/iot/Export/exportFeedList',
  exportHisFeedList:'/iot/Export/exportHisFeedList',
  exportEvnList:'/iot/Export/exportEvnList',
  exportPigSaleList:'/iot/Export/exportPigSaleList',
  exportHisPigSaleList:'/iot/Export/exportHisPigSaleList',
  exportVideoInventoryList:'/iot/Export/exportVideoInventoryList',
  exportHisVideoInventoryList:'/iot/Export/exportHisVideoInventoryList',
  exportEleWarnList:'/iot/Export/exportEleWarnList',
  exportHisEleWarnList:'/iot/Export/exportHisEleWarnList',
  exportFollowevent:'/iot/Export/exportFollowevent',

  // getWeather:'/HomePage/getWeather',
  getWeather:'/upms/PcRegion/getWeather',


  getAllRoles:"/iot/Ifspower/getAllRoles",
  // getCurrUserMenu:"/Ifspower/getCurrUserMenu",
  getCurrUserMenu:"/upms/menu/getMenusBySysCode",

  getRoleMenuIds:"/iot/Ifspower/getRoleMenuIds",
  addRoleOfMenu:"/iot/Ifspower/add",
  getUserList:"/upms/User/list",

  // getSysList:"/sso/sysList",
  getSysList:"/upms/UnifySmSysInfo/page",


  ssoRedirect:"/iot/sso/ssoRedirect",
  queryStreamUrl:"/iot/Pigpen/queryStreamUrl",

  getPigSaleInfo:"/iot/Pigsaledetailedlist/list",
  updatePigsaledetailedlist:"/iot/Pigsaledetailedlist/update",
  correctPigsaledetailedlist:"/iot/Pigsaledetailedlist/correct",
  PigDetailModifyRecordList:"/iot/PigDetailModifyRecord/list",
  updatePigSaleInfo:"/iot/Pigsaleinformation/updatePigSaleInfo",
  downloadVideo:"/iot/oss/download",
  getSysInfo:"/iot/client/sys/getsysInfo",
  getDeviceType:"/iot/Passageway/getTypeByFarmId",

  getHomePage:"/iot/HomePage/deviceIsOnlineCount",


  getSysListAll:'/upms/UnifySmSysInfo/sys/listAll?sysCode=IoTLogin',//获取可切换系统列表
  getBuildList:'/iot/Pigpen/getBuildings',//获取新的栋舍列表
}
