<template>
  <el-container>
    <el-aside width="auto">
      <CommonAside></CommonAside>
    </el-aside>
    <el-container>
      <el-header style="background-color:#409EFF">
        <CommonHeader></CommonHeader>
      </el-header>

      <common-tab></common-tab>
      <el-main style="background-color:#E7EAED;padding:10px">
        <!-- <keep-alive include='home,seo,foodDeviceList,foodLog,electricLog,electricDeviceList,electricWarnDeviceList,electricWarnLog,evnDeviceList,pigSaleList,pigSaleLog,videoDeviceList,videoLog,waterDeviceList,waterLog'>
          <router-view v-if='isRouterAliveMain'></router-view>
        </keep-alive> -->

        <!-- <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view> -->
        <keep-alive :include="cacheStr">
          <router-view ></router-view>
        </keep-alive>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import CommonAside from '../components/common/CommonAside.vue'
import CommonHeader from '../components/common/CommonHeader.vue'
import CommonTab from '../components/common/CommonTab.vue'
import loginApi from '../api/user/login'
import axios from 'axios'

export default {
  components: { CommonAside, CommonHeader, CommonTab },
  data() {
    return {
      isRouterAliveMain: true,
      cacheStr:[]
    }
  },
  watch:{
    "$store.state.tab.tabList":{
      handler(newVal, oldVal) {
        this.cacheStr=[]
        newVal.map((item)=>{
          this.cacheStr.push(item.target)
        });
        console.log(this.cacheStr)
      },
      deep: true
    
    }
  },
  provide() {
    return {
      reloadMain: this.reloadMain
    }
  },
  created() {},
  methods: {
    reloadMain() {
      this.isRouterAliveMain = false
      this.$nextTick(function() {
        this.isRouterAliveMain = true
      })
    }
  }
}
</script>
<style src="../assets/css/common.css"></style>
