<template>
  <div class="common-tab">
    <el-button size="mini" style="margin-left: 10px;margin-right: 2px" v-show="showLeftIcon" type="primary" icon="el-icon-arrow-left" @click="toLeft"></el-button>
    <div class="tab-menu" id="classifyScroll">
      <div class="classify" style="transition: all 0.3s;">
        <el-tag
          v-for="(tag, index) in tags"
          :key="index"
          :closable="tag.target !== 'home'"
          :disable-transitions="false"
          @click="changeMenu(tag)"
          @close="handleClose(tag)"
          :effect="$route.name === tag.target ? 'dark' : 'plain'"
        >
          <i :class="'el-icon-' + tag.icon" v-if="tag.icon"></i>
          <i :class="'el-icon-star-off'" v-if="tag.target !== 'home' && !collectionList.includes(tag.router)" @click="addCollection(tag)"></i>
          <i :class="'el-icon-star-on'" v-if="tag.target !== 'home' && collectionList.includes(tag.router)" @click="deleteCollection(tag)"></i>
          {{ tag.name }}
        </el-tag>
      </div>
    </div>

    <el-button style="margin-right: 10px;margin-left: 2px" size="mini" v-show="showRightIcon" type="primary" icon="el-icon-arrow-right" @click="rightClick"></el-button>
    <!--    <div class="refresh-btn">-->
    <!--      <el-button type="primary" icon="el-icon-refresh" @click="refresh"></el-button>-->
    <!--    </div>-->
  </div>
</template>
<script>
import { mapState } from 'vuex'
import other from '../../api/other/other'

export default {
  data() {
    return {
      collectionList: [],
      maxClickNum: 0, // 最大点击次数
      lastLeft: 0, // 上次滑动距离
      clickNum: 0, // 点击次数
      showRightIcon: true
    }
  },
  computed: {
    showLeftIcon() {
      return this.clickNum > 0
    },
    ...mapState({
      tags: state => state.tab.tabList
    })
  },
  watch: {
    // tags(arr){
    //     this.showRightIcon =  arr.length > 12
    // }
  },
  created() {
    // 获取刷新之前获取路由的信息
    const sessionTabs = JSON.parse(sessionStorage.getItem('tabs'))
    if (sessionTabs !== null) {
      // 获取到存储的路由列表之后，将信息利用vuex进行存储
      // this.$store.commit('changeSetTabs', sessionTabs.tabList)
    }
  },
  mounted() {
    this.listCollection()
    // 刷新之前存储路由信息
    window.addEventListener('beforeunload', e => {
      sessionStorage.setItem(
        'tabs',
        JSON.stringify({
          tabList: this.tags,
          activeTab: this.$route.name
        })
      )
    })
  },

  methods: {
    // 点击右箭头（左侧滚动）
    rightClick() {
      // 如果点击次数小于数组长度-1时，执行左滑动效果。
      if (this.clickNum < this.tags.length - 1) {
        // 获取当前元素宽度
        let width = document.querySelectorAll('.el-tag')[this.clickNum].offsetWidth
        // 获取最后一个元素距离左侧的距离
        let lastItemOffsetLeft = document.getElementsByClassName('el-tag')[this.tags.length - 1].offsetLeft
        // 获取可视区域宽度
        const lookWidth = document.getElementById('classifyScroll').clientWidth
        // 如果最后一个元素距离左侧的距离大于可视区域的宽度，表示最后一个元素没有出现，执行滚动效果
        if (lastItemOffsetLeft > lookWidth) {
          // 公示：滚动距离（元素的magin-left值） = 负的它自己的长度 + 上一次滑动的距离
          document.getElementsByClassName('classify')[0].style.marginLeft = `${-width + this.lastLeft}px`
          this.lastLeft = -width + this.lastLeft
          // 点击次数+1
          this.clickNum += 1
          // 记录到最后一个元素出现在可视区域时，点击次数的最大值。用于后面点击左侧箭头时判断右侧箭头的显示
          this.maxClickNum = this.clickNum
        }
        /*
          如果最后一个元素距离左侧的距离大于可视距离+自身的宽度，说明没有到最后一个, 显示右侧箭头，否则隐藏右侧箭头
          注意：这里要加上其自身的宽度，否则要多点击一下才会隐藏。因为点击的时候实际上最后一个元素还没有滚动，
          它距离左侧的距离是大于可视区域的（也就是说获取的是上一次点击之后，距离左侧的距离）
          所以这里要加上其自身的宽度
          看不懂的话不用管，反正加上就对了
        */
        // this.showRightIcon = lastItemOffsetLeft > lookWidth + width
      }
    },
    toLeft() {
      if (this.clickNum > 0) {
        // 获取当前元素宽度
        let width = document.querySelectorAll('.el-tag')[this.clickNum - 1].offsetWidth
        // 公示：滚动距离（元素的magin-left值） = 它自己的长度 + 上一次滑动的距离
        document.getElementsByClassName('classify')[0].style.marginLeft = `${this.lastLeft + width}px`
        this.lastLeft = width + this.lastLeft
        // 点击次数-1
        this.clickNum--
        // 如果点击次数小于最大点击次数，说明最后一个元素已经不在可是区域内了，显示右箭头
        if (this.clickNum < this.maxClickNum) {
          this.showRightIcon = true
        }
      }
    },
    // 点击切换页面方法
    changeMenu(item) {
      this.$store.commit('selectMenu', item)
      this.$router.push({ name: item.target, params: { refresh: false } })
    },
    // 关闭界面的方法
    handleClose(tag) {
      this.tags.forEach((item, index) => {
        if (item.target === tag.target && tag.target === this.$route.name) {
          const nextTab = this.tags[index + 1] || this.tags[index - 1]
          if (nextTab) {
            this.$store.commit('selectMenu', nextTab)
            this.$router.push({ name: nextTab.target })
          }
        } else {
          this.$router.push({ name: this.$route.name })
        }
      })
      this.$store.commit('closeTab', tag)
    },
    // 刷新方法
    refresh() {
      window.location.reload()
    },
    //收藏
    addCollection(tag) {
      let that = this
      other.addCollection({ menuIds: [tag.id] }).then(res => {
        if (res.code == 200) {
          this.$message.success('收藏成功')
          that.listCollection()
        }
      })
    },
    //取消收藏
    deleteCollection(tag) {
      let that = this
      other.removeCollection({ menuIds: [tag.id] }).then(res => {
        if (res.code == 200) {
          this.$message.success('取消成功')
          that.listCollection()
        }
      })
    },
    listCollection() {
      this.collectionList = []
      other.listCollection({ sysCode: 'iot' }).then(res => {
        if (res.result) {
          res.result.map(item => {
            this.collectionList.push(item.router)
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// tabs容器
.common-tab {
  display: flex;
  background-color: #f7f8f9;
  justify-content: space-between;
  align-items: center;
}

// tabs按钮容器
.tab-menu {
  flex: 1;
  background: #f7f8f9;
  padding: 10px;
  white-space: nowrap;
  transition: all 0.3s;
  overflow-x: hidden;

  .el-tag {
    margin-right: 15px;
    cursor: pointer;
  }
}

.refresh-btn {
  padding: 5px;
}

/*滚动条样式*/
// 滚动条整体部分
.tab-menu::-webkit-scrollbar {
  width: 4px;
  height: 2px;
}

// 滚动条里面的小方块，能上下移动,取决于是垂直滚动还是水平滚动
.tab-menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #498eaf;
}

// 滚动条的轨道
.tab-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: #fff;
}

.el-button--mini {
  padding: 7px 10px;
}
</style>
